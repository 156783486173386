

<section class="login-form-section">
    <div *ngIf="this.Pageies[0].login" class="flip">
    <div class="container">
        <h3 class="text-center mt-2" id="head">Add Smtp Data</h3>
        <div class="card mt-5">
            <div class="card-body">
        <form #form="ngForm">
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="inputEmail4">User Name</label>
        <input type="text" class="form-control" id="inputEmail4" placeholder="User Name"[(ngModel)]="smtp.UserName" name="UserName" id="UserName"
        #UserName="ngModel" required >
        <div *ngIf="UserName.invalid && (UserName.dirty || UserName.touched)" class="alert">

          <div style="color: red; " *ngIf="UserName.errors?.required">
              Please Enter User Name 
          </div>
      </div>
      </div>
      <div class="form-group col-md-6">
        <label for="inputPassword4">Password</label>
        <input type="password" class="form-control" id="inputPassword4" [(ngModel)]="smtp.Password" name="password" id="Password"
        #Password="ngModel" placeholder="Password">
        <div *ngIf="Password.invalid && (Password.dirty || Password.touched)" class="alert">

          <div style="color: red; " *ngIf="Password.errors?.required">
              Please Enter Password
          </div>
      </div>
      </div>
    </div>
    <div class="form-row">
 <div class="form-group col-md-6">
        <label for="inputAddress">From Email</label>
        <input type="text" class="form-control" id="inputAddress" [(ngModel)]="smtp.FromEamil" name="FromEamil" id="FromEamil"
        #FromEamil="ngModel" placeholder="From Email">
         <div *ngIf="FromEamil.invalid && (FromEamil.dirty || FromEamil.touched)" class="alert">

          <div style="color: red; " *ngIf="FromEamil.errors?.required">
              Please Enter From Email
          </div>
      </div>
      </div>
      <div class="form-group col-md-6">
        <label for="inputAddress">Manager Email</label>
        <input type="text" class="form-control" id="inputAddress" [(ngModel)]="smtp.MangerEmail" name="MangerEmail" id="MangerEmail"
        #MangerEmail="ngModel" placeholder="Manger Email">
         <div *ngIf="MangerEmail.invalid && (MangerEmail.dirty || MangerEmail.touched)" class="alert">

          <div style="color: red; " *ngIf="MangerEmail.errors?.required">
              Please Enter Manager Email
          </div>
      </div>
      </div>
    </div>
   
    <div class="form-row">
        <div class="form-group col-md-6">
            <label for="inputAddress">Server</label>
            <input type="text" class="form-control" id="inputAddress" [(ngModel)]="smtp.Server" name="Server" id="title"
            #Server="ngModel" placeholder="Server">
             <div *ngIf="Server.invalid && (Server.dirty || Server.touched)" class="alert">
    
              <div style="color: red; " *ngIf="Server.errors?.required">
                  Please Enter Server
              </div>
          </div>
          </div>
          <div class="form-group col-md-6">
            <label for="inputAddress">Port </label>
            <input type="text" class="form-control" id="inputAddress" [(ngModel)]="smtp.Port" name="Port" id="Port"
            #Port="ngModel" placeholder="port">
             <div *ngIf="Port.invalid && (Port.dirty || Port.touched)" class="alert">
    
              <div style="color: red; " *ngIf="Port.errors?.required">
                  Please Enter Port
              </div>
          </div>
          </div>
    </div>
    
    <div class="d-flex justify-content-center ">
      <button type="submit " id="btn" class="btn mr-2" style="background-color:#4a678e;color:white"(click)="onSave()"> Add Smtp</button>
      
  
  </div>
  </form>
  </div>
  </div>
    </div>
</div>
<div *ngIf="!this.Pageies[0].login" class="row">
    <div class="d-flex justify-content-center align-items-center w-100 h-100 error-container">
        <div class="col-xl-8 col-md-6 col-10">
            <div class="card border-0 box-shadow rounded-0">
                <div class="card-header d-flex justify-content-center align-items-center border-0 box-shadow">
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                </div>
                <div class="card-body text-center">
    
                    <h1 class="display-1"></h1>
                    
                     <p class="card-text"> Unauthorized access to a page.</p>                   
                 
                </div>
            </div>
        </div>
    </div>

</div>
</section>
