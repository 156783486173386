import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { department,VisitorList ,VisitModel,CarVisitModel} from 'src/app/models/department.model';

@Injectable({
  providedIn: 'root'
})
export class BusService {


  constructor(private http: HttpClient) { }
  getBusUrl()
  {
    return this.http.get(
      `${environment.apiUrl}Atttables?flag=1`
    );
  }
  getBusIdUrl(Id:number)
  {
    return this.http.get(
      `${environment.apiUrl}AttTables/${Id}?flag=1`
    );
  }
  addBus(data:any)
  {
    return this.http.post(
      `${environment.apiUrl}Atttables?flag=1`,data
    );
  }

  editBus(Id:number,data:any)
  {
    return this.http.put(
      `${environment.apiUrl}AttTables/${Id}?flag=1`,data
    );
  }
  deleteBus(Id:Number)
  {
    return this.http.delete(
      `${environment.apiUrl}AttTables/${Id}?flag=1`
    );
  }

  postNewvisitor (cont : CarVisitModel)
  {
    return this.http.post(`${environment.apiUrl}Carvisitor/Upload/`,cont)
  }
  //Update ....Update Item in Api
  Updatevisitor(id: number, cont: CarVisitModel) {

    return this.http.put(`${environment.apiUrl}Carvisitor/Upload/${id}`, cont)
  }



  ByUserid(id: number ) {
    return this.http.get(`${environment.apiUrl}Carvisitor/ByUserid/${id}`)
  }

  ByMangerid(id: number ) {
    return this.http.get(`${environment.apiUrl}Carvisitor/ByMangerid/${id}`)
  }
  GetvisitorById(id: number ) {

    return this.http.get(`${environment.apiUrl}Carvisitor/Getvisitor/${id}`)
  }

}
