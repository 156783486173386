import { Component, OnInit } from '@angular/core';
import { employee,empModel,empModelList } from 'src/app/models/employee.model';
import {  EmployeeService} from 'src/app/services/employee/employee.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import *  as XLSX from'xlsx';

import {ExcellModel,ExcellModel2} from 'src/app/models/Excell.model'
import { LoadingService } from 'src/app/models/loading.service'; 
import {  retry, retryWhen, delay, scan } from 'rxjs/operators';

import { CompanyService } from 'src/app/services/company/company.service';
import { Page } from 'src/app/models/Page.model';
declare var $:any;
import Swal from 'sweetalert2/dist/sweetalert2.js';

 

@Component({
  selector: 'app-listemployee',
  templateUrl: './listemployee.component.html',
  styleUrls: ['./listemployee.component.scss']
})
export class ListemployeeComponent implements OnInit {
  current:any=localStorage.getItem('UserId');
public modalRef: NgbModalRef;

loading$ = this.loader.loading$;
file;
isCompleted=false;
arrayBuffer;
filelist:any[]
progress: number = 0;
ISShowData: boolean = false;
empList: Array<ExcellModel> = [];
EmpSal: Array<ExcellModel2> = [];
EmpSalCut: Array<ExcellModel2> = [];

  public searchText: string;
  public p: any;
  public type: string = 'list';
  employees: empModelList[] = [];
  Pageies: Page[] = [];
  constructor(public loader: LoadingService,private companyService: CompanyService,
    public modalService: NgbModal,
    private router: Router,
    
    private employeeService: EmployeeService
    
  ) {
  }
  ngOnInit(): void {
    
    
    this.getListOftable();

  }
  
  public toggle(type) {
    this.type = type;
  }
  
  getListOftable() {
    
    this.employeeService.getEmployeeUrl(this.current).subscribe((res: any) => {
      this.employees = res;
      
      console.log(res);
    });

  }
  view(id: number, button: boolean) {
    this.router.navigate(['/addOrEditemployee/' + id + '/' + button])
  }
  addOrEdit(id: number) {
    this.router.navigate(['/addOrEditemployee', id])
  }
  Delete(element: any) {

    this.employeeService.deleteEmployee(element.Id).subscribe((res: any) => {
      this.getListOftable();
    });
  }
  public openModal(modalContent, employee) {

    this.modalRef = this.modalService.open(modalContent, { container: '.app' });

    this.modalRef.result.then((result) => {
      if (result === 'yes') {
        this.Delete(employee);
      }
    });
  }
  public closeModal() {
    this.modalRef.close();
  }


  addfile(event)     
{ 

 

//  this.loader.show();
  this.isCompleted=true;
this.file= event.target.files[0];     
let fileReader = new FileReader();    
fileReader.readAsArrayBuffer(this.file);     
fileReader.onload = (e) => {    
    this.arrayBuffer = fileReader.result;    
    var data = new Uint8Array(this.arrayBuffer);    
    var arr = new Array();    
    for(var i = 0; i != data.length; ++i) 
          /* create workbook */
    arr[i] = String.fromCharCode(data[i]);    
    var bstr = arr.join("");    
    var workbook = XLSX.read(bstr, {type:"binary",cellText:false,cellDates:true});
          /* selected the first sheet */   

           
    var first_sheet_name = workbook.SheetNames[0];    
    var worksheet = workbook.Sheets[first_sheet_name]; 
      /* save data */   
      
   // console.log(XLSX.utils.sheet_to_json(worksheet,{raw:true})); 
     // to get 2d array pass 2nd parameter as object {header: 1}   
      var arraylist = XLSX.utils.sheet_to_json(worksheet,{raw:false,dateNF:'dd/mm/yyyy'});    

      const headers1 = Object.keys(arraylist[0]);
    //  const headers2 = Object.keys(arraylist[1]);
      

    const CompId =Number( localStorage.getItem('currentLocation'));
     

       
    for(var Row = 0; Row != arraylist.length; ++Row)   
      {
        //console.log(arraylist[Row][headers[Column]],headers[Column],arraylist[Row]["Emp.ID"],arraylist[Row]["monthid"],arraylist[Row]["yearid"])
          
        let customObj = new ExcellModel();
     
        
        console.log("faisal")
        headers1[1].trim()

        customObj.Empcode = arraylist[Row]["Empcode"]; 
        customObj.EmpName = arraylist[Row]["EmpName"]; 
        customObj.Job = arraylist[Row]["Job"]; 
        customObj.Location = arraylist[Row]["Location"];
        customObj.Department=arraylist[Row]["Department"];


        customObj.ISIDCard = arraylist[Row]["ISIDCard"]; 
        customObj.IS111 = arraylist[Row]["IS111"]; 
        customObj.ISInsPrinted = arraylist[Row]["ISInsPrinted"]; 
        customObj.ISPhoto = arraylist[Row]["ISPhoto"];
        customObj.ISWorkPaper=arraylist[Row]["ISWorkPaper"];

        customObj.ISFish = arraylist[Row]["ISFish"]; 
        customObj.ISContract = arraylist[Row]["ISContract"]; 
        customObj.ISBirthDate = arraylist[Row]["ISBirthDate"]; 
        customObj.ISQualified = arraylist[Row]["ISQualified"];
        customObj.ISMilitary=arraylist[Row]["ISMilitary"];
        customObj.ISC1=arraylist[Row]["ISC1"];
        
        customObj.IDcard = arraylist[Row]["IDCard"]; 
        customObj.Phone = arraylist[Row]["Phone"]; 
        customObj.Add = arraylist[Row]["Add"]; 
        customObj.INsuranceNO = arraylist[Row]["INsuranceNO"];
        customObj.InsuranceTitle=arraylist[Row]["InsuranceTitle"];
        customObj.Qualified=arraylist[Row]["Qualified"];

        customObj.Insurance=arraylist[Row]["Insurance"];
        customObj.InsurancetDate=arraylist[Row]["InsurancetDate"];

        console.log(customObj.InsurancetDate)
        this.empList.push(customObj);
      }  
      this.progress=this.progress+1;
     
      
    
       // debugger
     


          this.filelist = [];    
          this.employeeService.PostExcelEmp(this.empList).subscribe(() => {this.isCompleted=false , this.alertWithSuccess()  }, error => {this.isCompleted=false ,console.log(error), this.alertWithError()} );

         
            //  this.loader.hide();
            
       
}    
}
update(){
  console.log("this.myVariable");
  this.alertWithSuccess();
  }
  alertWithSuccess(){
    Swal.fire('Thank you...', 'You submitted succesfully!', 'success')
  }

  alertWithError(){
    

    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'You submitted Faild!'
       
    })

  }
  addfile2(event)     
{ 

  
  //this.loader.show();
  this.isCompleted=true;
this.file= event.target.files[0];     
let fileReader = new FileReader();    
fileReader.readAsArrayBuffer(this.file);     
fileReader.onload = (e) => {    
    this.arrayBuffer = fileReader.result;    
    var data = new Uint8Array(this.arrayBuffer);    
    var arr = new Array();    
    for(var i = 0; i != data.length; ++i) 
          /* create workbook */
    arr[i] = String.fromCharCode(data[i]);    
    var bstr = arr.join("");    
    var workbook = XLSX.read(bstr, {type:"binary"}); 
          /* selected the first sheet */   
    var first_sheet_name = workbook.SheetNames[0];    
    var worksheet = workbook.Sheets[first_sheet_name]; 
      /* save data */   
      
   // console.log(XLSX.utils.sheet_to_json(worksheet,{raw:true})); 
     // to get 2d array pass 2nd parameter as object {header: 1}   
      var arraylist = XLSX.utils.sheet_to_json(worksheet,{raw:true});    

      const headers1 = Object.keys(arraylist[0]);
    //  const headers2 = Object.keys(arraylist[1]);
      

    const CompId =Number( localStorage.getItem('currentLocation'));
    let TotalV :number;

    debugger
   for(var ColumnX = 2; ColumnX != headers1.length; ++ColumnX)   
       {
      // var ColumnX=9
    //    this.EmpSal=[];
        TotalV=0;
    for(var Row = 0; Row != arraylist.length; ++Row)   
      {
        //console.log(arraylist[Row][headers[Column]],headers[Column],arraylist[Row]["Emp.ID"],arraylist[Row]["monthid"],arraylist[Row]["yearid"])
     
        if(arraylist[Row][headers1[ColumnX]]>0 && arraylist[Row][headers1[0]]!=null)
        {
        let customObj = new ExcellModel2();
     
       
        customObj.Empcode = arraylist[Row][headers1[0]]; 
        customObj.EmpName = arraylist[Row][headers1[1]]; 

        customObj.Head1 = headers1[ColumnX].trim(); 
        customObj.Col1 = arraylist[Row][headers1[ColumnX]]; 


        this.EmpSal.push(customObj);

        TotalV=TotalV+ customObj.Col1;  

        this.progress=this.progress+1;

       
        }
      
      }
      
     
 
        
   
    }
      
    this.employeeService.PostExcelEmpSal(this.EmpSal).subscribe(response => this.isCompleted=false , error => this.isCompleted=false);
    
     // debugger
       //   this.filelist = [];    
         // this.employeeService.PostExcelEmpSal(this.EmpSal).subscribe(() => this.update())
            //  this.loader.hide();
       
}    
}

addfile3()     
{ 
  this.isCompleted=true;

    

this.employeeService.CalculateAttendance().subscribe(() => {this.isCompleted=false , this.alertWithSuccess()  }, error => {this.isCompleted=false ,console.log(error), this.alertWithError()} );

}

}
