import { Component, OnInit } from '@angular/core';
import { shift } from 'src/app/models/shift.model';
import {  ShiftService} from 'src/app/services/shift/shift.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { Page } from 'src/app/models/Page.model';
import { CompanyService } from 'src/app/services/company/company.service';

import * as XLSX from 'xlsx'; 

@Component({
  selector: 'app-listshift',
  templateUrl: './listshift.component.html',
  styleUrls: ['./listshift.component.scss']
})
export class ListshiftComponent implements OnInit {
  public modalRef: NgbModalRef;
  public searchText: string;
  public p: any;
  public type: string = 'list';
  shifts: shift[] = [];
  Pageies: Page[] = [];
  constructor(
    public modalService: NgbModal,
    private router: Router,
    private shiftService: ShiftService,   private companyService: CompanyService,
  ) {
  }
  ngOnInit(): void {
    this.getListOftable();
    this.getPageper();
  }
  public toggle(type) {
    this.type = type;
  }
  getListOftable() {
    this.shiftService.getShiftUrl().subscribe((res: any) => {
      this.shifts = res;
    });

  }

  getPageper() {    
    this.companyService.getPageper(Number(localStorage.getItem('UserId').toString()),6).subscribe((res: any) => {
      this.Pageies = res;            
    });
  }

  exportAsXLSX():void {  
    const ws: XLSX.WorkSheet=XLSX.utils.json_to_sheet(this.shifts);
    console.log(this.shifts);
    
    delete (ws['C1'])
    delete (ws['D1'])
    delete (ws['E1'])
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Shifts');
    XLSX.writeFile(wb,"Shifts"+ '_' + new Date().getTime()+".xlsx")
 }
  view(id: number, button: boolean) {
    this.router.navigate(['/addOrEditshift/' + id + '/' + button])
  }
  addOrEdit(id: number) {
    this.router.navigate(['/addOrEditshift', id])
  }
  Delete(element: any) {

    this.shiftService.deleteShift(element.Id).subscribe((res: any) => {
      this.getListOftable();
    });
  }
  public openModal(modalContent, shift) {

    this.modalRef = this.modalService.open(modalContent, { container: '.app' });

    this.modalRef.result.then((result) => {
      if (result === 'yes') {
        this.Delete(shift);
      }
    });
  }
  public closeModal() {
    this.modalRef.close();
  }

}
