import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'employeeSearch'
})
export class EmployeeSearchPipe implements PipeTransform {

  transform(value, args?): Array<any> {
    let searchText = new RegExp(args, 'ig');
    if (value) {
      return value.filter(empModelList => {
        if( empModelList.name || empModelList.Depname || empModelList.ComNname ){
          if( empModelList.name.search(searchText) !== -1 || empModelList.Depname.search(searchText) !== -1 || empModelList.ComNname.search(searchText) !== -1 ){
            return true;
          }
        }
      });
    }
  }

}
