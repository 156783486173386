export interface jobLevel{
    Id:number;
    Enname:string;
    Arname:string;
    Fdate:string;
    Tdate:string;
}

export class CarVisitorList{

    Id:number;
    Carno:string;
    Carno2 :string;
   company :string;
   idcard :string;
   hostname :string;
   reason :string;
   denynote :string;
   status :number;
   cardnumber :number;
   CarComp :string;
   note :string;
   RegisterDate :string;
   signin :string;
   signout :string;
   Visitdate :string;
   VisitdateTo :string;
   CompID :number;
   DepID :number;
   userid  :number;
   ManagerId :number;

   Carkind :number;
   CarReason :number;


   DEPname :string;
   Compname :string;

    CarCompName :string;
   CarkindName :string;
   CarReasonName :string;

 
   DriverName: string;
   DriverID: string;
   DriverAdd: string;
} 