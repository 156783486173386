<div *ngIf="this.alert" class="alert alert-success alert-dismissible fade show" role="alert">
    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
    <strong>Success!</strong> You successfully Save changed this important alert message.
</div>
<!-- add branch  -->
<div class="row" *ngIf="this.worktimeId==0">
    <div class="col">
        <div widget class="card border-0 box-shadow">
            <div class="card-body widget-body">
                <div class="p-2 flex-grow-1 bd-highlight">
                    <a class="btn btn-info add-new" (click)="Backtolist()">
                        <i class="fa fa-arrow-left mr-2"></i>
                    </a>
                </div>
                <form [formGroup]="worktimeForm">
                    <div class="row">
                        <div class="col-lg-4 mb-4">
                            <div class="form-group">
                                <label for="Cost">Shift select</label>
                                <select class="form-control" formControlName="ShiftId" id="ShiftId">
                                    <option *ngFor="let shift of shifts" [value]="shift.Id">{{shift.Arname}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-4 mb-4">
                            <div class="form-group">
                                <label for="Fdate">From </label>
                                <div class="input-group">
                                    <input class="form-control" formControlName="FromDate" placeholder="yyyy-mm-dd"
                                        name="dp1" [(ngModel)]="modelCustom1" ngbDatepicker [dayTemplate]="From"
                                        [markDisabled]="isDisabled" #dc1="ngbDatepicker">
                                    <div class="input-group-append" (click)="dc1.toggle()">
                                        <span class="input-group-text"><i class="fa fa-calendar"></i></span>
                                    </div>
                                </div>
                            </div>
                            <ng-template #From let-date="date" let-currentMonth="currentMonth" let-selected="selected"
                                let-disabled="disabled">
                                <span class="custom-day" [class.weekend]="isWeekend(date)" [class.bg-primary]="selected"
                                    [class.hidden]="date.month !== currentMonth" [class.text-muted]="disabled">
                                    {{ date.day }}
                                </span>
                            </ng-template>
                        </div>
                        <div class="col-lg-4 mb-4">
                            <div class="form-group">
                                <label for="Tdate">to </label>
                                <div class="input-group">
                                    <input class="form-control" formControlName="ToDate" placeholder="yyyy-mm-dd"
                                        name="dp2" [(ngModel)]="modelCustom" ngbDatepicker [dayTemplate]="to"
                                        [markDisabled]="isDisabled" #dc="ngbDatepicker">
                                    <div class="input-group-append" (click)="dc.toggle()">
                                        <span class="input-group-text"><i class="fa fa-calendar"></i></span>
                                    </div>
                                </div>
                            </div>
                            <ng-template #to let-date="date" let-currentMonth="currentMonth" let-selected="selected"
                                let-disabled="disabled">
                                <span class="custom-day" [class.weekend]="isWeekend(date)" [class.bg-primary]="selected"
                                    [class.hidden]="date.month !== currentMonth" [class.text-muted]="disabled">
                                    {{ date.day }}
                                </span>
                            </ng-template>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 mb-4">
                            <div class="form-group">
                                <label for="OverTimeStart">OverTimeStart</label>
                                <ngb-timepicker [(ngModel)]="time3" formControlName="OverTimeStart"></ngb-timepicker>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-groupmt-4">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input checkbox-dark-gray"
                                        formControlName="IsHour" id="IsHour"> 
                                    <label class="custom-control-label" for="IsHour">isHour</label>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-groupmt-4">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input checkbox-dark-gray"
                                        formControlName="IsDayOff" id="IsDayOff">
                                    <label class="custom-control-label" for="IsDayOff">isDayOff</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 mb-4">
                            <div class="form-group">
                                <label for="StartSign">StartSign</label>
                                <ngb-timepicker [(ngModel)]="time1" formControlName="StartSign" ></ngb-timepicker>
                            </div>
                        </div>
                        
                        <div class="col-lg-4 mb-4">
                            <div class="form-group">
                                <label for="EndSign">EndSign</label>
                                <ngb-timepicker [(ngModel)]="time2" formControlName="EndSign" ></ngb-timepicker>
                            </div>
                        </div>
                        
                    </div>
                    
                    <div class="row">
                        <div class="col-lg-4 mb-4">
                            <div class="form-group">
                                <label for="StartShift">StartShift</label>
                                <ngb-timepicker [(ngModel)]="time4" formControlName="StartShift"></ngb-timepicker>
                                
                            </div>
                        </div>
                        <div class="col-lg-4 mb-4">
                            <div class="form-group">
                                <label for="LatePermission">LatePermission</label>
                                <ngb-timepicker [(ngModel)]="time7" formControlName="LatePermission"></ngb-timepicker>
                            </div>
                        </div>
                        
                    </div>
                    <div class="row  ">
                        <div class="col-lg-4 mb-4">
                            <div class="form-group">
                                <label for="EndShift">EndShift</label>
                                <ngb-timepicker [(ngModel)]="time5" formControlName="EndShift"></ngb-timepicker>
                            </div>
                        </div>
                        <div class="col-lg-4 mb-4">
                            <div class="form-group">
                                <label for="EarlyPermission">EarlyPermission</label>
                                <ngb-timepicker [(ngModel)]="time6" formControlName="EarlyPermission"></ngb-timepicker>
                            </div>
                        </div>

                        
                    </div>
                    <div class="row justify-content-center mt-5">
                        <button type="submit" class="btn btn-success mr-3" [disabled]="!worktimeForm.valid"
                            (click)="SubmitAdd()">Save</button>
                        <button class="btn btn-danger" (click)="clear()">Clear</button>
                    </div>
                </form>
            </div>
        </div>

    </div>
</div>

<!-- edit and view -->
<div class="row" *ngIf="this.worktimeId>0">
    <div class="col">
        <div widget class="card border-0 box-shadow">
            <div class="card-body widget-body">
                <div class="p-2 flex-grow-1 bd-highlight">
                    <a class="btn btn-info add-new" (click)="Backtolist()">
                        <i class="fa fa-arrow-left mr-2"></i>
                    </a>
                </div>
                <form [formGroup]="worktimeForm">
                    <div class="row">
                        <div class="col-lg-4 mb-4">
                            <div class="form-group">
                                <label for="Cost">Shift select</label>
                                <select [(ngModel)]="this.worktimes.ShiftId"   class="form-control" formControlName="ShiftId" id="ShiftId">
                                    <option  *ngFor="let shift of shifts" [value]="shift.Id">{{shift.Arname}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-4 mb-4">
                            <div class="form-group">
                                <label for="Fdate">From </label>
                                <div class="input-group">
                                    <input [(ngModel)]="this.worktimes.FromDate"  [readonly]="this.button" class="form-control" formControlName="FromDate" 
                                        name="dp1"  ngbDatepicker [dayTemplate]="From"
                                        [markDisabled]="isDisabled" #dc1="ngbDatepicker">
                                    <div class="input-group-append" (click)="dc1.toggle()">
                                        <span *ngIf="!this.button" class="input-group-text"><i class="fa fa-calendar"></i></span>
                                    </div>
                                </div>
                            </div>
                            <ng-template #From let-date="date" let-currentMonth="currentMonth" let-selected="selected"
                                let-disabled="disabled">
                                <span class="custom-day" [class.weekend]="isWeekend(date)" [class.bg-primary]="selected"
                                    [class.hidden]="date.month !== currentMonth" [class.text-muted]="disabled">
                                    {{ date.day }}
                                </span>
                            </ng-template>
                        </div>
                        <div class="col-lg-4 mb-4">
                            <div class="form-group">
                                <label for="Tdate">to </label>
                                <div class="input-group">
                                    <input [(ngModel)]="this.worktimes.ToDate"  [readonly]="this.button" class="form-control" formControlName="ToDate" 
                                        name="dp2"  ngbDatepicker [dayTemplate]="to"
                                        [markDisabled]="isDisabled" #dc="ngbDatepicker">
                                    <div class="input-group-append" (click)="dc.toggle()">
                                        <span *ngIf="!this.button" class="input-group-text"><i class="fa fa-calendar"></i></span>
                                    </div>
                                </div>
                            </div>
                            <ng-template #to let-date="date" let-currentMonth="currentMonth" let-selected="selected"
                                let-disabled="disabled">
                                <span class="custom-day" [class.weekend]="isWeekend(date)" [class.bg-primary]="selected"
                                    [class.hidden]="date.month !== currentMonth" [class.text-muted]="disabled">
                                    {{ date.day }}
                                </span>
                            </ng-template>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 mb-4">
                            <div class="form-group">
                                <label for="OverTimeStart">OverTimeStart</label>
                                <ngb-timepicker [(ngModel)]="this.worktimes.OverTimeStart"  formControlName="OverTimeStart"></ngb-timepicker>

                                
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-groupmt-4">
                                <div class="custom-control custom-checkbox">
                                    <input [(ngModel)]="this.worktimes.IsHour" type="checkbox" class="custom-control-input checkbox-dark-gray"
                                        formControlName="IsHour" id="IsHour">
                                    <label class="custom-control-label" for="IsHour">isHour</label>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-groupmt-4">
                                <div class="custom-control custom-checkbox">
                                    <input [(ngModel)]="this.worktimes.IsDayOff" type="checkbox" class="custom-control-input checkbox-dark-gray"
                                        formControlName="IsDayOff" id="IsDayOff">
                                    <label class="custom-control-label" for="IsDayOff">isDayOff</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 mb-4">
                            <div class="form-group">
                                <label for="StartSign">StartSign</label>
                                <ngb-timepicker [(ngModel)]="this.worktimes.StartSign" formControlName="StartSign" ></ngb-timepicker>
                            </div>
                        </div>
                        
                        <div class="col-lg-4 mb-4">
                            <div class="form-group">
                                <label for="EndSign">EndSign</label>
                                <ngb-timepicker  [(ngModel)]="this.worktimes.EndSign" formControlName="EndSign" ></ngb-timepicker>
                            </div>
                        </div>
                        
                    </div>
                    <div class="row">
                        <div class="col-lg-4 mb-4">
                            <div class="form-group">
                                <label for="StartShift">StartShift</label>
                                <ngb-timepicker [(ngModel)]="this.worktimes.StartShift" formControlName="StartShift"></ngb-timepicker>
                            </div>
                        </div>
                        <div class="col-lg-4 mb-4">
                            <div class="form-group">
                                <label for="LatePermission">LatePermission</label>
                                <ngb-timepicker [(ngModel)]="this.worktimes.LatePermission" formControlName="LatePermission"></ngb-timepicker>

                            </div>
                        </div>
                        
                    </div>
                    <div class="row  ">
                        
                        <div class="col-lg-4 mb-4">
                            <div class="form-group">
                                <label for="EndShift">EndShift</label>
                                <ngb-timepicker [(ngModel)]="this.worktimes.EndShift" formControlName="EndShift"></ngb-timepicker>
                            </div>
                        </div>
                        <div class="col-lg-4 mb-4">
                            <div class="form-group">
                                <label for="EarlyPermission">EarlyPermission</label>
                                <ngb-timepicker [(ngModel)]="this.worktimes.EarlyPermission" formControlName="EarlyPermission"></ngb-timepicker>

                            </div>
                        </div>
                        
                    </div>
                    <div class="row justify-content-center mt-5" *ngIf="!this.button">
                        <button type="submit" class="btn btn-success mr-3" [disabled]="!worktimeForm.valid"
                            (click)="SubmitEdit()">Save</button>
                        <button class="btn btn-danger" (click)="clear()">Clear</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>