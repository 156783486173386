import { Component, OnInit } from '@angular/core';
import { publicHoliday } from 'src/app/models/publicholiday.model';
import { PublicHolidayService } from 'src/app/services/publicHoliday/public-holiday.service';
import { Page } from 'src/app/models/Page.model';
import { CompanyService } from 'src/app/services/company/company.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as XLSX from 'xlsx'; 

@Component({
  selector: 'app-listpublicholiday',
  templateUrl: './listpublicholiday.component.html',
  styleUrls: ['./listpublicholiday.component.scss']
})
export class ListpublicholidayComponent implements OnInit {
  public modalRef: NgbModalRef;
  public searchText: string;
  public p: any;
  public type: string = 'list';
  publichliodays: publicHoliday[] = [];
  Pageies: Page[] = [];
  constructor(
    public modalService: NgbModal,
    private router: Router,
    private publichliodayService: PublicHolidayService,private companyService: CompanyService,
  ) {
  }
  ngOnInit(): void {
    this.getListOftable();
    this.getPageper();
  }
  public toggle(type) {
    this.type = type;
  }

  exportAsXLSX():void {  
    const ws: XLSX.WorkSheet=XLSX.utils.json_to_sheet(this.publichliodays);
      delete (ws['B1'])
    // delete (ws['D1'])
    // delete (ws['E1'])
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Public Holiday');
    XLSX.writeFile(wb,"Public Holiday"+ '_' + new Date().getTime()+".xlsx")
 }
  getListOftable() {
    this.publichliodayService.getPublicHolidayUrl().subscribe((res: any) => {
      this.publichliodays = res;
    });

  }

  getPageper() {    
    this.companyService.getPageper(Number(localStorage.getItem('UserId').toString()),7).subscribe((res: any) => {
      this.Pageies = res;            
    });
  }

  view(id: number, button: boolean) {
    this.router.navigate(['/addOrEditpublicholihday/' + id + '/' + button])
  }
  addOrEdit(id: number) {
    this.router.navigate(['/addOrEditpublicholihday', id])
  }
  Delete(element: any) {

    this.publichliodayService.deletePublicHoliday(element.Id).subscribe((res: any) => {
      this.getListOftable();
    });
  }
  public openModal(modalContent, publichlioday) {

    this.modalRef = this.modalService.open(modalContent, { container: '.app' });

    this.modalRef.result.then((result) => {
      if (result === 'yes') {
        this.Delete(publichlioday);
      }
    });
  }
  public closeModal() {
    this.modalRef.close();
  }
}
