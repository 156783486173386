import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { department,VisitorList ,VisitModel,CarVisitModel} from 'src/app/models/department.model';
 

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {

  constructor(private http: HttpClient) { }

  Siginout(id: number, cont: VisitorList) {

    return this.http.put(`${environment.apiUrl}visitor/signout/${id}`, cont)
  }

  Sigin(id: number, cont: VisitorList) {

    return this.http.put(`${environment.apiUrl}visitor/Signin/${id}`, cont)
  }

  GetAllvisitor() {
    return this.http.get(`${environment.apiUrl}visitor/GetvisitorIN`);
  }

  getDepartmentUrl()
  {
    return this.http.get(
      `${environment.apiUrl}Atttables?flag=4`
    );
  }
 
  
  getDepartmentIdUrl(Id:number)
  {
    return this.http.get(
      `${environment.apiUrl}AttTables/${Id}?flag=4`
    );
  }
  addDepartment(data:any)
  {
    return this.http.post(
      `${environment.apiUrl}Atttables?flag=4`,data
    );
  }

  editDepartment(Id:number,data:any)
  {
    return this.http.put(
      `${environment.apiUrl}AttTables/${Id}?flag=4`,data
    );
  }
  deleteDepartment(Id:Number)
  {
    return this.http.delete(
      `${environment.apiUrl}AttTables/${Id}?flag=4`
    );
  }

 
  ByUserid(id: number ) {
    return this.http.get(`${environment.apiUrl}visitor/ByUserid/${id}`)
  }

  ByMangerid(id: number ) {
    return this.http.get(`${environment.apiUrl}visitor/ByMangerid/${id}`)
  }
  
  

   
  GetvisitorById(id: number ) {

    return this.http.get(`${environment.apiUrl}visitor/Getvisitor/${id}`)
  }

  postNewvisitor (cont : VisitModel)
  {
    return this.http.post(`${environment.apiUrl}visitor/Web/`,cont)
  }
  //Update ....Update Item in Api
  Updatevisitor(id: number, cont: VisitModel) {

    return this.http.put(`${environment.apiUrl}visitor/web/${id}`, cont)
  }
}
