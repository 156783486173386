import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {SmtpModel} from 'src/app/models/smtp.model'

@Injectable({
  providedIn: 'root'
})
export class SmtpService {

  constructor(private http :HttpClient) { }

  GetAllSmtp() {
    return this.http.get(`${environment.apiUrl}Smtpdatas`);
  }
  
  postNewSmtp (smtp : SmtpModel)
  {
    return this.http.post(`${environment.apiUrl}Smtpdatas/`,smtp)
  }

  
  GetSmtpById(id: number ) {

    return this.http.get(`${environment.apiUrl}smtpdatas/${id}`)
  }

  //Update ....Update Item in Api
  UpdateSmtp(id: number, smtp: SmtpModel) {

    return this.http.put(`${environment.apiUrl}Smtpdatas/${id}`, smtp)
  }
  //Delete ....Delete Item from Api
  DeleteSmtp(id: number) {

    return this.http.delete(`${environment.apiUrl}Smtpdatas/${id}`);
  }

}