<div class="row">
    <div class="col-xl-2 col-lg-4 col-md-4 col-sm-6 pl-2 pr-2 mb-4">
      <div class="w-100 h-100p">
          <ngx-charts-number-card
              [scheme]="salesBgColor"
              [textColor]="'#fff'"
              [innerPadding]="0"
              [results]="sales"
              [valueFormatting]="infoValueFormat"
              [labelFormatting]="infoLabelFormat"
              (select)="onSelect($event)">
          </ngx-charts-number-card>     
      </div>
    </div>
    <div class="col-xl-2 col-lg-4 col-md-4 col-sm-6 pl-2 pr-2 mb-4">
      <div class="w-100 h-100p">
          <ngx-charts-number-card
              [scheme]="likesBgColor"
              [textColor]="'#fff'"
              [innerPadding]="0"
              [results]="likes"
              [valueFormatting]="infoValueFormat"
              [labelFormatting]="infoLabelFormat"
              (select)="onSelect($event)">
          </ngx-charts-number-card>     
      </div>
    </div>
    <div class="col-xl-2 col-lg-4 col-md-4 col-sm-6 pl-2 pr-2 mb-4">
      <div class="w-100 h-100p">
          <ngx-charts-number-card
              [scheme]="downloadsBgColor"
              [textColor]="'#fff'"
              [innerPadding]="0"
              [results]="downloads"
              [valueFormatting]="infoValueFormat"
              [labelFormatting]="infoLabelFormat"
              (select)="onSelect($event)">
          </ngx-charts-number-card>     
      </div>
    </div>
    <div class="col-xl-2 col-lg-4 col-md-4 col-sm-6 pl-2 pr-2 mb-4">
      <div class="w-100 h-100p">
          <ngx-charts-number-card
              [scheme]="profitBgColor"
              [textColor]="'#fff'"
              [innerPadding]="0"
              [results]="profit"
              [valueFormatting]="infoValueFormat"
              [labelFormatting]="infoLabelFormat"
              (select)="onSelect($event)">
          </ngx-charts-number-card>     
      </div>
    </div>
    <div class="col-xl-2 col-lg-4 col-md-4 col-sm-6 pl-2 pr-2 mb-4">
      <div class="w-100 h-100p">
          <ngx-charts-number-card
              [scheme]="messagesBgColor"
              [textColor]="'#fff'"
              [innerPadding]="0"
              [results]="messages"
              [valueFormatting]="infoValueFormat"
              [labelFormatting]="infoLabelFormat"
              (select)="onSelect($event)">
          </ngx-charts-number-card>     
      </div>
    </div>
    <div class="col-xl-2 col-lg-4 col-md-4 col-sm-6 pl-2 pr-2 mb-4">
      <div class="w-100 h-100p">
          <ngx-charts-number-card
              [scheme]="membersBgColor"
              [textColor]="'#fff'"
              [innerPadding]="0"
              [results]="members"
              [valueFormatting]="infoValueFormat"
              [labelFormatting]="infoLabelFormat"
              (select)="onSelect($event)">
          </ngx-charts-number-card>     
      </div>
    </div>
</div>