import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JobService {

 
  constructor(private http: HttpClient) { }
  getJobUrl()
  {
    return this.http.get(
      `${environment.apiUrl}Atttables?flag=7`
    );
  }

  getVisitorReason()
  {
    return this.http.get(
      `${environment.apiUrl}Atttables?flag=5`
    );
  }

  getCarkind()
  {
    return this.http.get(
      `${environment.apiUrl}Atttables?flag=0`
    );
  }

  getCarComp()
  {
    return this.http.get(
      `${environment.apiUrl}Atttables?flag=3`
    );
  }

  getCarReason()
  {
    return this.http.get(
      `${environment.apiUrl}Atttables?flag=1`
    );
  }
   
 

  getList(Flageid: number)
  {
    return this.http.get(
      `${environment.apiUrl}Atttables?flag=`+Flageid.toString()
    );
  }
  getJobIdUrl_flage(Id:number,Flageid: number)
  {
    return this.http.get(
      `${environment.apiUrl}AttTables/${Id}?flag=`+Flageid.toString()
    );
  }
  getJobIdUrl(Id:number)
  {
    return this.http.get(
      `${environment.apiUrl}AttTables/${Id}?flag=5`
    );
  }
  addJob_flage(data:any,Flageid: number)
  {
    return this.http.post(
      `${environment.apiUrl}Atttables?flag=`+Flageid.toString(),data
    );
  }
  addJob(data:any)
  {
    return this.http.post(
      `${environment.apiUrl}Atttables?flag=5`,data
    );
  }

  editJob_flage(Id:number,data:any,Flageid: number)
  {
    return this.http.put(
      `${environment.apiUrl}AttTables/${Id}?flag=`+Flageid.toString(),data
    );
  }
  editJob(Id:number,data:any)
  {
    return this.http.put(
      `${environment.apiUrl}AttTables/${Id}?flag=5`,data
    );
  }
  deleteJob(Id:Number)
  {
    return this.http.delete(
      `${environment.apiUrl}AttTables/${Id}?flag=5`
    );
  }

  deleteJob_flage(Id:Number,Flageid: number)
  {
    return this.http.delete(
      `${environment.apiUrl}AttTables/${Id}?flag=`+Flageid.toString()
    );
  }
}
