import { Component, OnInit } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators, FormBuilder} from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { company } from 'src/app/models/company.model';
import { cost } from 'src/app/models/cost.model';
import { branch } from 'src/app/models/branch.model';
import { department } from 'src/app/models/department.model';
import { job } from 'src/app/models/job.model';
import { SalaryBand, EmpSalaryBand,FileDatas,ListAttTable,EmpExpenses,EmpPenality} from 'src/app/models/SalaryBand.model';
 
 
 

import { BusService } from 'src/app/services/bus/bus.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { CostService } from 'src/app/services/cost/cost.service';
import { BranchService } from 'src/app/services/branch/branch.service';
import { DepartmentService } from 'src/app/services/department/department.service';
import { EmployeeService } from 'src/app/services/employee/employee.service';
import { JobService } from 'src/app/services/job/job.service';
import { employee ,empModel} from 'src/app/models/employee.model';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
 
import {  saveAs as importedSaveAs  } from "file-saver"; 


import { Page } from 'src/app/models/Page.model';

@Component({
  selector: 'app-add-or-editemployee',
  templateUrl: './add-or-editemployee.component.html',
  styleUrls: ['./add-or-editemployee.component.scss']
})
export class AddOrEditemployeeComponent implements OnInit {
  public modelCustom: NgbDateStruct;
  public modelCustom1: NgbDateStruct;
  public type: string = 'list';
  fileToUpload: any;
  Branchs:job[]=[];
  Pageies: Page[] = [];
  costs:cost[]=[];
  departments:job[]=[];
  jobs:job[]=[];

  ExpensesList:ListAttTable[]=[];
  Papers:ListAttTable[]=[];
  PenalityList:ListAttTable[]=[];

  SalaryBands:SalaryBand[]=[];
  EmpExpenses_:EmpExpenses[]=[];
  EmpPenality_:EmpPenality[]=[]
  FileDatas_:FileDatas[]=[];
  EmpSalaryBands:EmpSalaryBand[]=[];
  
  companies:company[]=[];
  empid:any;
  empArr!:any;
  employeeForm:FormGroup;
  EmpSalary:FormGroup;
  EmpExpenses:FormGroup;
  EmpPenality:FormGroup;
  fileForm:FormGroup;
  EmpPapperAdd:FormGroup;
  button:boolean;
  alert:boolean=false;
  earning:number;
  Expenses:number;
  TEmpPenality:number;
  todayBreakfast=0;

  constructor(
    private location: Location,
    
    private CostSerives:CostService,
    private BranchServices:BranchService,
    private departmentService:DepartmentService,
    private jobService:JobService,
    private employeeService:EmployeeService,
    private companyService:CompanyService,
    private activateRout:ActivatedRoute,
    private http:  HttpClient,
  ) { 

  }
  ngOnInit(): void {
    this.button=JSON.parse(this.activateRout.snapshot.paramMap.get('button'));
    this.empid = this.activateRout.snapshot.paramMap.get('id');
    console.log('id', this.empid)
    if (this.empid != 0) {
      this.employeeService.getEmployeeIdUrl(this.empid).subscribe((res: any) => {
        
        this.empArr = res
      
      })

     
   
     
      
     // this.getEmppep();
    }
    this.getTodayBreakfastCount();
   
   
    this.getdropdownCost();
    this.getdropdownBranch();
    this.getdropdownDepartment();
    this.getdropdownJob();
    this.getdropdownCompany();
    this.getSalaryband() ;
    this.getExpensesband();

    this.EmpSalary   =new FormGroup({EmpCode:new FormControl,Salaryid:new FormControl,value :new FormControl ,Note :new FormControl})
    this.EmpExpenses =new FormGroup({EmpCode:new FormControl,Expensesid:new FormControl,value :new FormControl ,Note :new FormControl})
    this.EmpPenality =new FormGroup({EmpCode:new FormControl,Penalityid:new FormControl,value :new FormControl ,Note :new FormControl})

    this.EmpPapperAdd =new FormGroup({EmpCode:new FormControl,paperid:new FormControl})

    this.fileForm = new FormGroup({
      altText: new FormControl(''),
      description: new FormControl('')
    });
    this.employeeForm=new FormGroup({
      Name:new FormControl('', [Validators.required]) ,          
      DepID:new FormControl('', [Validators.required]) ,
      jobTitle:new FormControl(),
     
      CompID:new FormControl('', [Validators.required]) ,
      UserLevel:new FormControl('', [Validators.required]) ,
   
      Email:new FormControl() ,
      username:new FormControl() ,
      Password:new FormControl() ,
      Phone:new FormControl(),
      IsManager:new FormControl() ,
      IsActive:new FormControl(),

      Person:new FormControl('') ,
      Car:new FormControl('') ,
      
      
  
 

    });
    this.getPageper(15);
  }
  getPageper(Flageid: number) {   
     
    this.companyService.getPageper(Number(localStorage.getItem('UserId').toString()),Flageid).subscribe((res: any) => {
      this.Pageies = res;   
      
    });
  }
  // get dropdown cost
  getdropdownCost() {
    this.CostSerives.getCostUrl().subscribe((res: any) => {
   this.costs = res;
    });
  }

  // get dropdown Branch
  getdropdownBranch() {
    this.BranchServices.getBranchesUrl().subscribe((res: any) => {
   this.Branchs = res;
    });
  }

  
    // get dropdown department
    getdropdownDepartment() {
      this.departmentService.getDepartmentUrl().subscribe((res: any) => {
     this.departments = res;
      });
    }
    // get dropdown job
    getdropdownJob() {
      this.jobService.getJobUrl().subscribe((res: any) => {

     this.jobs = res;
      });
    }
 
    getSalaryband() {
      this.employeeService.getSalaryband().subscribe((res: any) => {
     this.SalaryBands = res;
     
      });
    }

    getExpensesband() {
      this.jobService.getList(26).subscribe((res: any) => {
        this.ExpensesList = res;
         });
    }

   

     

    downloadFile(id:number, contentType: string,FileFormat:string)
    {
        
    this.employeeService.downloadFile(id).subscribe(res => {
        
      importedSaveAs(res,contentType.trim()+FileFormat.trim());});

    }

     

     

    

     

    getTodayBreakfastCount(){this.employeeService.GetCount().subscribe(a=>{this.todayBreakfast=a as number})}

    // get dropdown company
    getdropdownCompany()
    {
      this.companyService.getCompanyUrl().subscribe((res: any) => {
        this.companies = res;
         });
    }

  get f(){
    return this.employeeForm.controls;
  }
  submitAdd()
  {
     
    
     
    this.employeeService.addemployee( this.employeeForm.value).subscribe((res:any) => {
         console.log('Employee Added successfully!');
         this.alert=true;      
        });
        this.Backtolist();
  }
  
  handleFileInput(e: any) {
    this.fileToUpload = e?.target?.files[0];
  }
  
  
  submitEdit()
  {
     
 
    
    if (this.empid==0)
    {
    this.employeeService.addemployee( this.employeeForm.value).subscribe((res:any) => {
      console.log('Employee Added successfully!');
      this.alert=true;      
     });
    }
    
else
{
   
  
    this.employeeService.editDepartment( this.empid,this.employeeForm.value).subscribe((res:any) => {
         console.log('Employee Added successfully!');
         this.alert=true;
        
        });
      }
        this.Backtolist();
          
  }
  
  public isWeekend(date: NgbDateStruct) {
    const d = new Date(date.year, date.month - 1, date.day);
    return d.getDay() === 0 || d.getDay() === 6;
  }
  public isDisabled(date: NgbDateStruct, current: {month: number}) {
    return date.month !== current.month;
  }
  Backtolist()
  {
    this.location.back();
  }
  clear()
  {
    this.employeeForm.reset();
    this.alert=false;

  }
  result: any;
  returndate(year: number, month: number, day: number) {

    if (month < 10) {
      this.result = year.toString() + "-" + "0" + month.toString();
    }
    else {
      this.result = year.toString() + "-" + month.toString();
    }

    if (day < 10) {
      this.result = this.result + "-" + "0" + day.toString();

    }
    else {
      this.result = this.result + "-" + day.toString();

    }

    return this.result;
  } 
}
