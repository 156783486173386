<div *ngIf="this.alert" class="alert alert-success alert-dismissible fade show" role="alert">
    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
    <strong>Success!</strong> You successfully Save changed this important alert message.
</div>
<!-- add joblevel  -->
<div class="row" *ngIf="this.joblevelId==0">
    <div class="col">
        <div widget class="card border-0 box-shadow">
            <div class="card-body widget-body">
                <div class="p-2 flex-grow-1 bd-highlight">
                    <a class="btn btn-info add-new" (click)="Backtolist()">
                        <i class="fa fa-arrow-left mr-2"></i>
                    </a>
                </div>
                <form [formGroup]="joblevelForm">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="Arname">joblevel Code</label>
                                <input type="text" class="form-control" formControlName="Arname" id="Arname"
                                    placeholder="joblevel Name">
                                <div *ngIf="f['Arname'].touched && f['Arname'].invalid" class="alert alert-danger">
                                    <div *ngIf="f['Arname'].errors && f['Arname'].errors['required']">joblevel Code is
                                        required.</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="Enname">joblevel Name</label>
                                <input type="text" class="form-control" formControlName="Enname" id="Enname"
                                    placeholder="joblevel Code">
                                <div *ngIf="f['Enname'].touched && f['Enname'].invalid" class="alert alert-danger">
                                    <div *ngIf="f['Enname'].errors && f['Enname'].errors['required']">joblevel Name is
                                        required.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center mt-5">
                        <button type="submit" class="btn btn-success mr-3" [disabled]="!joblevelForm.valid"
                            (click)="SubmitAdd()">Save</button>
                        <button class="btn btn-danger" (click)="clear()">Clear</button>
                    </div>
                </form>
            </div>
        </div>

    </div>
</div>

<!-- edit and view -->
<div class="row" *ngIf="this.joblevelId>0">
    <div class="col">
        <div widget class="card border-0 box-shadow">
            <div class="card-body widget-body">
                <div class="p-2 flex-grow-1 bd-highlight">
                    <a class="btn btn-info add-new" (click)="Backtolist()">
                        <i class="fa fa-arrow-left mr-2"></i>
                    </a>
                </div>
                <form [formGroup]="joblevelForm">
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label for="Carno">Plate No.</label>
                                <input [(ngModel)]="this.joblevels.Carno" [readonly]="this.button" type="text"
                                    class="form-control" formControlName="Carno" id="Carno">
                                <div *ngIf="f['Carno'].touched && f['Carno'].invalid" class="alert alert-danger">
                                    <div *ngIf="f['Carno'].errors && f['Carno'].errors['required']">joblevel Nmae is
                                        required.</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label for="Carno2">Plate Letter</label>
                                <input [(ngModel)]="this.joblevels.Carno2" [readonly]="this.button" type="text"
                                    class="form-control" formControlName="Carno2" id="Carno2">
                                <div *ngIf="f['Carno2'].touched && f['Carno2'].invalid" class="alert alert-danger">
                                    <div *ngIf="f['Carno2'].errors && f['Carno2'].errors['required']">joblevel Nmae is
                                        required.</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-4">
                            <div class="form-group">
                                <label for="Phone">host name</label>
                                <input type="text" class="form-control" [(ngModel)]="this.joblevels.hostname" [readonly]="this.button" class="form-control" formControlName="hostname" >
                            </div>
                        </div>
 

                    </div>

                    <div class="row">
                        <div class="col-sm-4">
                        <div class="form-group">
                            <label for="Dep">Car Company  </label>
                            <select [(ngModel)]="this.joblevels.CarComp"   class="form-control" formControlName="CarComp" id="CarComp">
                                <option  *ngFor="let item of CarCompV" [value]="item.ID">
                                    {{item.name}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-sm-4">
                        <div class="form-group">
                            <label for="Dep">Vehicle Type  </label>
                            <select [(ngModel)]="this.joblevels.Carkind"   class="form-control" formControlName="Carkind" id="Carkind">
                                <option  *ngFor="let item of CarkindV" [value]="item.ID">
                                    {{item.name}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-sm-4">
                        <div class="form-group">
                            <label for="Dep">Visit Reason  </label>
                            <select [(ngModel)]="this.joblevels.CarReason"   class="form-control" formControlName="CarReason" id="CarReason">
                                <option  *ngFor="let item of CarReasonV" [value]="item.ID">
                                    {{item.name}}</option>
                            </select>
                        </div>
                    </div>

                       </div>

                       <div class="row">

                      
                       <div class="col-sm-4">
                        <div class="form-group">
                            <label for="Phone">Driver name</label>
                            <input type="text" class="form-control" [(ngModel)]="this.joblevels.DriverName" [readonly]="this.button" class="form-control" formControlName="DriverName" >
                        </div>
                    </div>

                    <div class="col-sm-4">
                        <div class="form-group">
                            <label for="Phone">Driver ID</label>
                            <input type="text" class="form-control" [(ngModel)]="this.joblevels.DriverID" [readonly]="this.button" class="form-control" formControlName="DriverID" >
                        </div>
                    </div>

                    <div class="col-sm-4">
                        <div class="form-group">
                            <label for="Phone">Driver Address</label>
                            <input type="text" class="form-control" [(ngModel)]="this.joblevels.DriverAdd" [readonly]="this.button" class="form-control" formControlName="DriverAdd" >
                        </div>
                    </div>


                </div>

                <button class="btn btn-danger" >Scane ID</button>

            

              


                <div  class="row">
                    <div class="col-12">
                        <div class="table-responsive bg-white box-shadow">
                            <table class="table table-hover">
                                <thead>
                                    <tr> 
                                        
                                        <th>Entry Time </th>
                                        
                                          <th> Entry  Image </th>
                                          <th>   Exit time   </th>
                
                                        <th> Exit Image   </th>
                
                                   
                
                                    </tr>
                                </thead>
                                <tbody>
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                    <div class="row justify-content-center mt-5" *ngIf="!this.button">
                        <button type="submit" class="btn btn-success mr-3" [disabled]="!joblevelForm.valid"

                            (click)="SubmitEdit()">Save</button>
                        <button class="btn btn-danger" (click)="clear()">Clear</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>