import { Pipe, PipeTransform } from '@angular/core';
import { VisitorList } from 'src/app/models/department.model';

@Pipe({
  name: 'transactionSearch'
})
export class TransactionSearchPipe implements PipeTransform {

  transform(value, args?): Array<any> {
    let searchText = new RegExp(args, 'ig');
    if (value) {
      return value.filter(VisitorList => {
        if(VisitorList.visitorname|| VisitorList.cardnumber  ){
          if(VisitorList.visitorname.search(searchText) !== -1 || VisitorList.cardnumber.search(searchText) !== -1  ){
            return true;
          }
        }
      });
    }
  }

}
