import { Component, OnInit } from '@angular/core';
import { job } from 'src/app/models/job.model';
import {  JobService} from 'src/app/services/job/job.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ExcelService} from  'src/app/services/excel/excel.service';

import { CompanyService } from 'src/app/services/company/company.service';
import { Page } from 'src/app/models/Page.model';

import * as XLSX from 'xlsx'; 

@Component({
  selector: 'app-list-two-field',
  templateUrl: './list-two-field.component.html',
  styleUrls: ['./list-two-field.component.scss']
})
export class ListTwoFieldComponent implements OnInit {

  Flageid:any;
  public modalRef: NgbModalRef;
  public searchText: string;
  public p: any;
  public type: string = 'list';
  jobs: job[] = [];
  Pageies: Page[] = [];
  sites=[];
  constructor(private excelService:ExcelService,
    public modalService: NgbModal,
    private router: Router,
    private jobService: JobService,private companyService: CompanyService,private activateRout:ActivatedRoute
  ) {
  }
  ngOnInit(): void {

    this.Flageid=this.activateRout.snapshot.paramMap.get('id');
    
    this.getListOftable(this.Flageid);
    //this.getPageper(this.Flageid);
  }
  public toggle(type) {
    this.type = type;
  }
  exportAsXLSX():void {  
    const ws: XLSX.WorkSheet=XLSX.utils.json_to_sheet(this.jobs);
    delete (ws['C1'])
    delete (ws['D1'])
    delete (ws['E1'])
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'jobs');
    XLSX.writeFile(wb,"jobs"+ '_' + new Date().getTime()+".xlsx")
 }

  getListOftable(Flageid: number) {
    this.jobService.getList(Flageid).subscribe((res: any) => {
      this.jobs = res;
      console.log(this.jobs)
      
    });

  }
  
/*
  getPageper(Flageid: number) {   
     
    this.companyService.getPageper(Number(localStorage.getItem('UserId').toString()),Flageid).subscribe((res: any) => {
      this.Pageies = res;   
      console.log   (res,"faisal");  
    });
  }
  */

  view(id: number, button: boolean) {        
    this.router.navigate(['/AddTwoField/'+ id+'/'+this.Flageid.toString()+ '/' + button])     
  }

  addOrEdit(id: number) {    
    this.router.navigate(['/AddTwoField/'+ id+'/'+this.Flageid.toString()])
  }

  addNew(id: number) {    
    this.router.navigate(['/AddTwoField/'+ id+'/'+this.Flageid.toString()])
  }
  Delete(element: any) {

    this.jobService.deleteJob_flage(element.Id,this.Flageid).subscribe((res: any) => {
      this.getListOftable(this.Flageid);
    });
  }
  public openModal(modalContent, job) {

    this.modalRef = this.modalService.open(modalContent, { container: '.app' });

    this.modalRef.result.then((result) => {
      if (result === 'yes') {
        this.Delete(job);
      }
    });
  }
  public closeModal() {
    this.modalRef.close();
  }
}
