<app-info-panels></app-info-panels>

<app-info-cards></app-info-cards>

<div class="row mb-2">
    <div class="col-lg-6 mb-4">
        <app-visitors></app-visitors>
    </div>  
    <div class="col-lg-6 mb-4">
        <app-cost></app-cost>
    </div>   
</div>

<div class="row">
    <div class="col-lg-5 mb-4">
        <app-disk-space>   </app-disk-space>
    </div>  
    <div class="col-lg-7 mb-4">
        <app-todo></app-todo>
    </div>   
</div>