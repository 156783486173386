<div widget class="card border-0">
    <div class="card-header transparent border-0 text-muted">
        <h5 class="mb-0">Disk Space</h5>
        <div class="widget-controls"> 
            <a data-widgster="expand" href="#" class="transition"><i class="fa fa-chevron-down"></i></a>
            <a data-widgster="collapse" href="#" class="transition"><i class="fa fa-chevron-up"></i></a>                         
            <a data-widgster="close" href="#" class="transition"><i class="fa fa-times"></i></a>
        </div>        
    </div>
    <div class="card-body pt-0 pl-2 pr-2 pb-2 widget-body">
        <div class="text-center mb-2 fs-13">
            <div class="custom-control custom-checkbox custom-control-inline">
                <input type="checkbox" class="custom-control-input checkbox-dark-gray" id="checkboxExplodeSlices" [checked]="explodeSlices" (change)="explodeSlices = !explodeSlices">
                <label class="custom-control-label" for="checkboxExplodeSlices">Explode Slices</label>
            </div>
            <div class="custom-control custom-checkbox custom-control-inline">
                <input type="checkbox" class="custom-control-input checkbox-dark-gray" id="checkboxShowLabels" [checked]="showLabels" (change)="showLabels = !showLabels">
                <label class="custom-control-label" for="checkboxShowLabels">Show Labels</label>
            </div>
        </div>
        <div class="w-100 h-300p">
            <ngx-charts-pie-chart
                [scheme]="colorScheme"
                [results]="data"
                [legend]="showLegend"
                [explodeSlices]="explodeSlices"
                [labels]="showLabels"
                [doughnut]="doughnut"
                [gradient]="gradient"
                (select)="onSelect($event)">
            </ngx-charts-pie-chart>   
        </div>
    </div>
</div>