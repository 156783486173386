import { Component, OnInit } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { bus } from 'src/app/models/bus.model';
import { BusService } from 'src/app/services/bus/bus.service';
import { job } from 'src/app/models/job.model';
import { JobService } from 'src/app/services/job/job.service';
import { department ,CarVisitModel} from 'src/app/models/department.model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-add-or-editbus',
  templateUrl: './add-or-editbus.component.html',
  styleUrls: ['./add-or-editbus.component.scss']
})
export class AddOrEditbusComponent implements OnInit {
  current:any=localStorage.getItem('UserId');

  VisitorReasonList:job[]=[];
  VehicalTypeList:job[]=[];
  CarCompanyList:job[]=[];
  Pena = new CarVisitModel;
  alert:boolean=false;
  buss!: bus;
  busForm:FormGroup;
  busId:any;
  button:any;
  constructor(private location: Location,
    public busservices:BusService,
    private datePipe: DatePipe,
    private jobService:JobService,
    private router: Router,
    private activateRout:ActivatedRoute,) { }
    public modelCustom: NgbDateStruct;
  ngOnInit(): void {

    this.getCarReason_List();
    this.getCarkind_List();
    this.getCarComp_List();

    this.busId=this.activateRout.snapshot.paramMap.get('id');
    this.button=this.activateRout.snapshot.paramMap.get('button');
    console.log(this.button);
    if(this.busId>0)
    {
      this.busservices.GetvisitorById(this.busId).subscribe((res:any)=>{
        this.Pena = res;
        this.Pena.Visitdate  =this.datePipe.transform(this.Pena.Visitdate, 'yyyy-MM-dd', 'en-US');
      })

    }
    this.busForm = new FormGroup({
      Arname: new FormControl('', [Validators.required]),
      Enname: new FormControl('', Validators.required),
    });
  }
  get f(){
    return this.busForm.controls;
  }

  getCarReason_List() {
    this.jobService.getCarReason().subscribe((res: any) => {

   this.VisitorReasonList = res;
    });
  }

  getCarComp_List() {
    this.jobService.getCarComp().subscribe((res: any) => {

   this.CarCompanyList = res;
    });
  }

  getCarkind_List() {
    this.jobService.getCarkind().subscribe((res: any) => {

   this.VehicalTypeList = res;
    });
  }

  SubmitAdd(){


    this.Pena.Userlevel=10;
    this.Pena.UserAdded=this.current;
    
    if(this.busId>0)
    this.SubmitEdit();
     else
    this.busservices.postNewvisitor(this.Pena).subscribe((res:any) => {
         console.log('company Added successfully!');
         this.alert=true;
    })

    this.Backtolist();

    
  }
  SubmitEdit(){
    
    
    this.busservices.Updatevisitor( this.busId,this.Pena).subscribe((res:any) => {
         console.log('company Updated successfully!');
         this.location.back();
    })
  }
  Backtolist()
  {
    this.location.back();
  }
  clear()
  {
    this.busForm.reset();
    this.alert=false;

  }

}
