import { Component, OnInit } from '@angular/core';
import { employee } from 'src/app/models/employee.model';
import { leave } from 'src/app/models/Leave.model';
import { reportdepartment } from 'src/app/models/reportdepartment.model';
import { reporttimetable } from 'src/app/models/reportname.model';
import { EmployeeService } from 'src/app/services/employee/employee.service';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import { BranchService } from 'src/app/services/branch/branch.service';
import { branch } from 'src/app/models/branch.model';

import { DepartmentService } from 'src/app/services/department/department.service';
import { department } from 'src/app/models/department.model';

import { ShiftService } from 'src/app/services/shift/shift.service';
import { shift } from 'src/app/models/shift.model';

import { JobService} from 'src/app/services/job/job.service';
import { job } from 'src/app/models/job.model';

import { ReportService } from 'src/app/services/report/report.service';
import { reportname } from 'src/app/models/reporttimetable.model';
import { SalaryBand} from 'src/app/models/SalaryBand.model';

import { IDropdownSettings, } from 'ng-multiselect-dropdown';

import { CompanyService } from 'src/app/services/company/company.service';
import { Page } from 'src/app/models/Page.model';
import Swal from 'sweetalert2/dist/sweetalert2.js';

 

@Component({
  selector: 'app-report-att',
  templateUrl: './report-att.component.html',
  styleUrls: ['./report-att.component.scss']
})
export class ReportAttComponent implements OnInit {
  
  dropdownSettings = {};
  dropdownList = [];
  Pageies: Page[] = [];
  SalaryBands:SalaryBand[]=[];
  DepList:string="";
  isShow=2
  Templ;
  Fempl;
  Branch;
  Depart;
  job1;
  Shift1;
  FromDate;
  ToDate;
  Report;
  Fdate;
  Tdate;
  Salaryid;
  public modelCustom: NgbDateStruct;
  public modelCustom1: NgbDateStruct;
  searchForm: FormGroup;
  selectedItems =[];
  jobs:job[]=[];
  shifts:shift[]=[];
  Branchs:branch[]=[];
  departments:department[]=[];
  employees: employee[] = [];
  reportnames: reportname[] = [];
  userid!: number;
  reportid!: number;
  reportdepartments: reportdepartment[] = [];
  reporttimetables: reporttimetable[] = [];
  Leaves: leave[] = [];
  queryString: any;

  constructor(private employeeService: EmployeeService,private JobService: JobService,private companyService: CompanyService,
    private departmentService:DepartmentService,private reportService: ReportService, private BranchServices:BranchService,private ShiftServices:ShiftService) {
  }

  ngOnInit(): void {
   
    

    

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'Id',
      textField: 'Enname',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      
      allowSearchFilter: true
    };
  
    
  //  this.getListOfemployees();
    this.getReportNames();
    this.getdropdownDepartment();
    this.getdropdownBranch();
    this.getSalaryband();
   // this.getReportTimeTables();
   this.getdropdownShift();
   this.getdropdownjob();
    this.searchForm = new FormGroup({
      Femp: new FormControl(),
      Temp: new FormControl(),
      Fdate: new FormControl('', [Validators.required]),
      Tdate: new FormControl('', [Validators.required]),
      
      Div:new FormControl() ,
      
      Salaryid: new FormControl(),
      Dep: new FormControl(),
      Shift: new FormControl(),
      Job2: new FormControl(),
      Rep: new FormControl(),
    });
    this.getPageper(17);
  }
  getPageper(Flageid: number) {   
     
    this.companyService.getPageper(Number(localStorage.getItem('UserId').toString()),Flageid).subscribe((res: any) => {
      this.Pageies = res;   
      
    });
  }

  onItemSelect(item: any) {
   
    this.selectedItems.push(item.Id)
  }
  onSelectAll(items: any) {
    
    
      this.selectedItems.forEach((value,index)=>{
           this.selectedItems.splice(index);
      });
  

    items.forEach((element)=>{
      
      this.selectedItems.push(element.Id)
      
   });

 
    console.log(this.selectedItems);
  }

  onDeSelectAll(items: any) {this.selectedItems.forEach((value,index)=>{
    this.selectedItems.splice(index);
});}

  onItemDeSelect(items: any) {
   
    this.selectedItems.forEach((element,index)=>{
      if(element==items.Id)  this.selectedItems.splice(index,1);
   });

    
  }


 
  getReportNames() {
    this.reportService.getReportName2().subscribe((res: any) => {
      this.reportnames = res;
    });
  }
   // get dropdown Branch
   getdropdownBranch() {
     
    this.BranchServices.getBranchesUrl().subscribe((res: any) => {
   this.Branchs = res;
    });
  }

   // get dropdown Shift
   getdropdownShift() {
     
    this.ShiftServices.getShiftUrl().subscribe((res: any) => {
   this.shifts = res;
    });
  }

  getdropdownjob() {
     
    this.JobService.getJobUrl().subscribe((res: any) => {
   this.jobs = res;
    });
  }

  getSalaryband() {
  
    this.employeeService.getSalaryband().subscribe((res: any) => {
      this.SalaryBands = res;
      
       });
  }

   // get dropdown department
   getdropdownDepartment() {
    this.departmentService.getDepartmentUrl().subscribe((res: any) => {
   this.departments = res;
    });
  }

  getReportDepartments() {
    this.reportService.getReportDepartment().subscribe((res: any) => {
      this.reportdepartments = res;
    });
  }
  getReportTimeTables() {
    this.reportService.getReportTimeTable().subscribe((res: any) => {
      this.reporttimetables = res;
    });
  }

  getLeaves() {
    this.reportService.getLeaves().subscribe((res: any) => {
      console.log(res)
      this.Leaves = res;
    });
  }
  searchReport() {
    if (this.reportid) {
      Object.keys(this.searchForm.value).forEach((k) => {
        if (this.searchForm.value[k] == "") {
          this.searchForm.value[k] = null
        }
      })
      let params = new URLSearchParams();
      for (let key in this.searchForm.value) {
        params.set(key, this.searchForm.value[key])
      }
      this.queryString = params.toString();
      this.queryString = decodeURIComponent(this.queryString)
      this.reportService.filterReport(this.userid, this.reportid, this.queryString).subscribe((res: any) => {
      });
    }

  }
  get f(){
    return this.searchForm.controls;
  }
  clear() {
    this.searchForm.reset();

  }
  public isWeekend(date: NgbDateStruct) {
    const d = new Date(date.year, date.month - 1, date.day);
    return d.getDay() === 0 || d.getDay() === 6;
  }
  public isDisabled(date: NgbDateStruct, current: { month: number }) {
    return date.month !== current.month;
  }


  convertToReport(Type:string){
    
    this.Fdate=this.returndate(this.FromDate.year,this.FromDate.month,this.FromDate.day);
    this.Tdate=this.returndate(this.ToDate.year,this.ToDate.month,this.ToDate.day);
    this.Depart="";

    let dataDep="";
    
    this.selectedItems.forEach((value,index)=>{
      
      dataDep=dataDep+','+value.toString();
  })

    var s2=dataDep.substring(1);

  

  

 
 window.open("https://65.108.96.96:12300/?flage="+ this.Report +"&System="+Type+"&Fdate="+this.Fdate+"&Tdate="+this.Tdate+"&Company="+this.job1+"&Department="+this.Depart+"&Job="+this.Branch+"&EmpCode="+this.Fempl+"&EmpCode1="+this.Templ,"_self");
  
  //this.ngOnInit();

  }
  result: any;
  returndate(year: number, month: number, day: number) {

    if (month < 10) {
      this.result = year.toString()  + "0" + month.toString();
    }
    else {
      this.result = year.toString()  + month.toString();
    }

    if (day < 10) {
      this.result = this.result  + "0" + day.toString();

    }
    else {
      this.result = this.result  + day.toString();

    }

    return this.result;
  } 

  onOptionsSelected(Index:number)
  {
    console.log(Index)
    if (Index==110)
    this.isShow=1;
    else
    this.isShow=2;

  }

}
