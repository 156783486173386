import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'companySearch'
})
export class CompanySearchPipe implements PipeTransform {

  transform(value, args?): Array<any> {
    let searchText = new RegExp(args, 'ig');
    if (value) {
      return value.filter(company => {
        if(company.name || company.name ){
          if(company.name.search(searchText) !== -1 || company.name.search(searchText) !== -1 ){
            return true;
          }
        }
      });
    }
  }
}
