export class ExcellModel{
    Empcode:string;
    EmpName:string;
    Job:string;
    Location:string;   
    Department:string;
    

         ISIDCard:string;
        // ISHealth:string;
   
         IS111:string;
         ISInsPrinted:string;
         ISPhoto:string;
         ISWorkPaper:string;
         ISFish:string;
   
         ISContract:string;
         ISBirthDate:string;
         ISQualified:string;
         ISMilitary:string;
         ISC1:string;
         Insurance:string;

       IDcard :string;
        Phone :string;
        Add :string;
        INsuranceNO :string;
        InsuranceTitle :string;
       Qualified :string;

       InsurancetDate  :Date;   


}

export class ExcellModel2{
 
  Empcode:string;
  EmpName:string;
  Col1:number;
  Head1:string;
 
}