import { Component, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { MenuService } from '../menu/menu.service';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { Menu } from '../menu/menu.model';
import { usersystempage } from 'src/app/models/usersystempage';
 
import { verticalMenuItems } from '../menu/menu';
import {filter, map} from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ MenuService ]
})
export class SidebarComponent implements OnInit {  

   userpage: usersystempage[] = [];
   public userpage2: Array<Menu> = [];
   verticalMenuItems2: Array<Menu>=[]; 

  public settings: Settings;
  public menuItems   :Array<Menu>;
  public menuItems2   :Array<Menu>;

 // _menuItems$: Observable<Menu[]>;
  
  constructor(public appSettings:AppSettings, public menuService:MenuService,private permission: PermissionService) {

   
      this.settings = this.appSettings.settings;

      if (localStorage.getItem('ISAdmin').toString()=='1')
      this.menuItems=  this.menuService.getVerticalMenuItems_Admin();//this.menuItems=  this.menuService.getVerticalMenuItems_Admin();//
      if (localStorage.getItem('ISAdmin').toString()=='2')
       this.menuItems=  this.menuService.verticalMenuItems_SubAdmin();
       if (localStorage.getItem('ISAdmin').toString()=='3')
       this.menuItems=  this.menuService.getverticalMenuItems_User();
       if (localStorage.getItem('ISAdmin').toString()=='4')
       this.menuItems=  this.menuService.verticalMenuItems_SEC();
       
 
      console.log(this.menuItems)
      console.log(this.menuItems2)
  
  }

  

   ngOnInit() {     


  
   // this. Length();
    //this.refreh();

   // this.verticalMenuItems2.push(new Menu(13, 'Employees', 'employee', null, 'user', null, false, 0))
     // this.menuItems=this.verticalMenuItems2;
      
   // this.menuItems=this.userpage2;//this.menuService.getVerticalMenuItems();
/*
    if(sessionStorage["userMenuItems"]) {
      let ids = JSON.parse(sessionStorage.getItem("userMenuItems"));
      let newArr = [];
      ids.forEach(id => {
        let newMenuItem = this.menuItems.filter(mail => mail.id == id);
        newArr.push(newMenuItem[0]);
      });
      this.menuItems = newArr; 
    }
    */
  }
 
 refreh()
 {
 
 

    console.log(this.menuItems)
     
 }
    

  public closeSubMenus(){
     

    let menu = document.querySelector("#menu0");
    for (let i = 0; i < menu.children.length; i++) {
        let child = menu.children[i].children[1]; 
        if(child){
            if(child.classList.contains('show')){
              child.classList.remove('show');
              menu.children[i].children[0].classList.add('collapsed'); 
            }             
        }
    }
   
  }


}
