import { Component, OnInit } from '@angular/core';
import { department,VisitorList } from 'src/app/models/department.model';
import {  DepartmentService} from 'src/app/services/department/department.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {  interval  } from 'rxjs';
import { Page } from 'src/app/models/Page.model';
import * as XLSX from 'xlsx'; 

@Component({
  selector: 'app-listdepartment',
  templateUrl: './listdepartment.component.html',
  styleUrls: ['./listdepartment.component.scss']
})
export class ListdepartmentComponent implements OnInit {
  current:any=localStorage.getItem('UserId');
  public modalRef: NgbModalRef;
  public searchText: string;
  public p: any;
  public type: string = 'list';
  departments: VisitorList[] = [];
  Pageies: Page[] = [];
  
  constructor(
    public modalService: NgbModal,
    private router: Router,
    private departmentService: DepartmentService, private CompanyService:CompanyService)
  {
    this.getListOftable();
  }
  ngOnInit(): void {
      
    interval(10000).subscribe((val) => {this.getListOftable()});
  }
  public toggle(type) {
    this.type = type;
  }
  getListOftable() {
    this.departmentService.ByUserid(this.current).subscribe((res: any) => {
      this.departments = res;

    });

  }

  

  exportAsXLSX():void {  
    const ws: XLSX.WorkSheet=XLSX.utils.json_to_sheet(this.departments);
    delete (ws['C1'])
    delete (ws['D1'])
    delete (ws['E1'])
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Department');
    XLSX.writeFile(wb,"Department"+ '_' + new Date().getTime()+".xlsx")
 }
  view(id: number, button: boolean) {
    this.router.navigate(['/addOrEditdepartment/' + id + '/' + button])
  }
  addOrEdit(id: number) {
    this.router.navigate(['/addOrEditdepartment', id])
  }
  Delete(element: any) {

    this.departmentService.deleteDepartment(element.Id).subscribe((res: any) => {
      this.getListOftable();
    });
  }
  public openModal(modalContent, department) {

    this.modalRef = this.modalService.open(modalContent, { container: '.app' });

    this.modalRef.result.then((result) => {
      if (result === 'yes') {
        this.Delete(department);
      }
    });
  }
  public closeModal() {
    this.modalRef.close();
  }

  Signin(id: number) {
    var x=new VisitorList;
  
    this.departmentService.Sigin(id,x).subscribe(data => {

      console.log("Successfully sigin");
      this.ngOnInit();

    },
      err => {
          alert("Sorry Can't Delete Unexpected Action During Opration");
        
      });

  }

  SignOut(id: number) {
    var x=new VisitorList;
  
    this.departmentService.Siginout(id,x).subscribe(data => {

      console.log("Successfully signout");
      this.ngOnInit();

    },
      err => {
          alert("Sorry Can't Delete Unexpected Action During Opration");
        
      });

  }

}
