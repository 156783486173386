import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SmtpService } from 'src/app/services/smtp/Smtp.service';
import { SmtpModel } from 'src/app/models/smtp.model'
import { CompanyService } from 'src/app/services/company/company.service';
import { Page } from 'src/app/models/Page.model';


@Component({
  selector: 'app-smtp',
  templateUrl: './smtp.component.html',
  styleUrls: ['./smtp.component.scss']
})

export class SmtpComponent implements OnInit {
  smtp=new SmtpModel();
  getAll:SmtpModel[];
  Pageies: Page[] = [];
  id:number;
  eid:number;
    constructor(private smSrc:SmtpService, private route:ActivatedRoute, private router:Router,private companyService: CompanyService) { 
      
    }
  
    ngOnInit(): void {
      
               this.smSrc.GetSmtpById(this.eid).subscribe(res=>{
          this.smtp=res as SmtpModel
          console.log(this.smtp);
        })
      
      
      this.getPageper(18);
    }
    getPageper(Flageid: number) {   
       
      this.companyService.getPageper(Number(localStorage.getItem('UserId').toString()),Flageid).subscribe((res: any) => {
        this.Pageies = res;   

        console.log(res)
        
      });
    }
  
  
    onSave(){
      if(this.id==0){
        this.smSrc.postNewSmtp(this.smtp).subscribe(res => {
          this.smtp = new SmtpModel();
          this.router.navigateByUrl('/smtp');
        
      },
    err => {
      if (err.status == 0) {
        this.router.navigate(['/access']);
      }
    });
    }
      else if(this.id==1){
        this.smSrc.UpdateSmtp(this.eid,this.smtp).subscribe(res => {
          this.smtp = new SmtpModel();
    
           
          this.ngOnInit();
         
        
    
      },
      err => {
        if (err.status == 0) {
          this.router.navigate(['/access']);
        }
      });
      }
      else{
        this.smSrc.GetSmtpById(this.eid).subscribe(res=>{
          this.smtp=res as SmtpModel
          this.router.navigateByUrl('/smtp');
  
        },
        err => {
          if (err.status == 0) {
            this.router.navigate(['/access']);
          }
        })
      }
      }
     
    
    getAllList() {
      this.smSrc.GetAllSmtp().subscribe(data => {
        this.getAll = data as SmtpModel[]
        for (let e of this.getAll) {
         console.log(e);
  
        }
      },
      err => {
        if (err.status == 0) {
          this.router.navigate(['/access']);
        }
      });
    }
     myFunction() {
      var x = document.createElement("BUTTON");
      var t = document.createTextNode("Click me");
      x.appendChild(t);
      document.body.appendChild(x);
    }
  }
  
  