import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { jobLevel,CarVisitorList } from 'src/app/models/jobLevel.model';

@Injectable({
  providedIn: 'root'
})
export class JobLevelService {

  constructor(private http: HttpClient) { }

  Siginout(id: number, cont: CarVisitorList) {

    return this.http.put(`${environment.apiUrl}Carvisitor/signout/${id}`, cont)
  }

  Sigin(id: number, cont: CarVisitorList) {

    return this.http.put(`${environment.apiUrl}Carvisitor/Signin/${id}`, cont)
  }

  GetAllvisitor() {
    return this.http.get(`${environment.apiUrl}Carvisitor/GetvisitorIN`);
  }

  getVisitor(Id:number)
  {
    return this.http.get(
      `${environment.apiUrl}Carvisitor/Getvisitor/${Id}`
    );
  }

  getJobLevelUrl()
  {
    return this.http.get(
      `${environment.apiUrl}Atttables?flag=6`
    );
  }
  getJobLevelIdUrl(Id:number)
  {
    return this.http.get(
      `${environment.apiUrl}AttTables/${Id}?flag=6`
    );
  }
  addJobLevel(data:any)
  {
    return this.http.post(
      `${environment.apiUrl}Atttables?flag=6`,data
    );
  }

  GetCarReason()
  {
    return this.http.get(
      `${environment.apiUrl}Atttables?flag=1`
    );
  }

  getCarComp()
  {
    return this.http.get(
      `${environment.apiUrl}Atttables?flag=3`
    );
  }

  getCarKind()
  {
    return this.http.get(
      `${environment.apiUrl}Atttables?flag=0`
    );
  }

  editJobLevel(Id:number,data:any)
  {
    return this.http.put(
      `${environment.apiUrl}AttTables/${Id}?flag=6`,data
    );
  }
  deleteJobLevel(Id:Number)
  {
    return this.http.delete(
      `${environment.apiUrl}AttTables/${Id}?flag=6`
    );
  }
}
