<div *ngIf="this.Pageies[0].login" class="flip">
    <div class="row" >
        <div class="col">
            <div widget class="card border-0 box-shadow">
                <div class="card-body widget-body">
    
                    <form [formGroup]="searchForm">
                        <div class="row">
                            <div class="col-lg-4 mb-4">
                                <div class="form-group">
                                    <label for="Rep">Report Name</label>
                                    <select class="form-control" formControlName="Rep" id="Rep" (change)='onOptionsSelected($event.target.value)'  [(ngModel)]="Report">
                                        <option *ngFor="let reportname of reportnames" [value]="reportname.Id" >{{reportname.Enname}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                            
    
                     
                            <!-- fdate -->
                            <div class="row">
                            <div class="col-lg-4 mb-4">
                                <div class="form-group">
                                    <label for="Fdate">From </label>
                                    <div class="input-group">
                                        <input class="form-control" formControlName="Fdate" placeholder="yyyy-mm-dd"  [(ngModel)]="FromDate"
                                            name="dp1"   ngbDatepicker [dayTemplate]="From"
                                            [markDisabled]="isDisabled" #dc1="ngbDatepicker">
                                            
                                        <div class="input-group-append" (click)="dc1.toggle()">
                                            <span class="input-group-text"><i class="fa fa-calendar"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <ng-template #From let-date="date" let-currentMonth="currentMonth" let-selected="selected" 
                                    let-disabled="disabled">
                                    <span class="custom-day" [class.weekend]="isWeekend(date)" [class.bg-primary]="selected"
                                        [class.hidden]="date.month !== currentMonth" [class.text-muted]="disabled">
                                        {{ date.day }}
                                    </span>
                                </ng-template>
                            </div>
                            <!-- todate -->
                            <div class="col-lg-4 mb-4">
                                <div class="form-group">
                                    <label for="Tdate">to </label>
                                    <div class="input-group">
                                        <input class="form-control" formControlName="Tdate" placeholder="yyyy-mm-dd" [(ngModel)]="ToDate"
                                            name="dp2"   ngbDatepicker [dayTemplate]="to"
                                            [markDisabled]="isDisabled" #dc="ngbDatepicker">
                                            
                                        <div class="input-group-append" (click)="dc.toggle()">
                                            <span class="input-group-text"><i class="fa fa-calendar"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <ng-template #to let-date="date" let-currentMonth="currentMonth" let-selected="selected"
                                    let-disabled="disabled">
                                    <span class="custom-day" [class.weekend]="isWeekend(date)" [class.bg-primary]="selected"
                                        [class.hidden]="date.month !== currentMonth" [class.text-muted]="disabled">
                                        {{ date.day }}
                                    </span>
                                </ng-template>
                            </div>

                            
                        </div>
                        <div class="row">
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label for="Femp">From Employee</label>
                                    <input formControlName="Femp" type="text" class="form-control" id="Femp" [(ngModel)]="Fempl">
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label for="Temp">To Employee</label>
                                    <input formControlName="Temp" type="text" class="form-control" id="Temp" [(ngModel)]="Templ">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 mb-4">
                                <div class="form-group">
                                    <label for="Comp">Branch</label>
                                    <select class="form-control" formControlName="Div" id="Div" [(ngModel)]="Branch">
                                        <option *ngFor="let cost of Branchs" [value]="cost.Id"  >{{cost.Enname}}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-lg-4 mb-4">
                            <ng-multiselect-dropdown
  [placeholder]="'Department List'"
  [settings]="dropdownSettings"
  [data]="departments"
  [(ngModel)]="selectedItems"
  (onSelect)="onItemSelect($event)"
  (onSelectAll)="onSelectAll($event)"
  (onDeSelect)="onItemDeSelect($event)"
  (onDeSelectAll)="onDeSelectAll($event)" >
>
</ng-multiselect-dropdown>

</div>

 


                            <div class="col-lg-4 mb-4">
                                <div class="form-group">
                                    <label for="Dep">Department</label>
                                    <select class="form-control" formControlName="Dep" id="Dep" [(ngModel)]="Depart">
                                        <option *ngFor="let department of departments" [value]="department.Id">{{department.Enname}}
                                        </option>
                                    </select>
                                </div>
                            </div>
    
                            <!--
                            <div class="col-lg-4 mb-4">
                                <div style="width:50%">
                                    <div class="form-group">
                                        <label for="Dep">Department</label>
                                    <ng-multiselect-dropdown>
                                        [settings]="dropdownSettings"
                                        [data]="dropdownList">
                                    </ng-multiselect-dropdown>
                                </div>
                                </div>
                                
                        </div>
    -->
                            <div class="col-lg-4 mb-4">
                                <div class="form-group">
                                    <label for="shift">Job</label>
                                    <select class="form-control" formControlName="Job2" id="Job2"   [(ngModel)]="job1">
                                        <option *ngFor="let Leave of jobs" [value]="Leave.Id">{{Leave.Enname}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            
                        </div>
                        <div class="row justify-content-center mt-5">
                           
                            
                            <button type="submit" class="btn btn-success mr-3" [disabled]="!searchForm.valid"
                                (click)="convertToReport('XLS')">XLS </button>
    
                                <button type="submit" class="btn btn-success mr-3" [disabled]="!searchForm.valid"
                                (click)="convertToReport('PDF')">PDF </button>
    
                            <button class="btn btn-danger"  (click)="clear()">Clear</button>
    
                            
    
                        </div>
                    </form>
                </div>
            </div>
    
        </div>
    </div>
    
    
    </div>
    
    <div *ngIf="!this.Pageies[0].login" class="row">
        <div class="d-flex justify-content-center align-items-center w-100 h-100 error-container">
            <div class="col-xl-8 col-md-6 col-10">
                <div class="card border-0 box-shadow rounded-0">
                    <div class="card-header d-flex justify-content-center align-items-center border-0 box-shadow">
                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                    </div>
                    <div class="card-body text-center">
        
                        <h1 class="display-1"></h1>
                        
                         <p class="card-text"> Unauthorized access to a page.</p>                   
                     
                    </div>
                </div>
            </div>
        </div>
    
    </div>