import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'leaveSearch'
})
export class LeaveSearchPipe implements PipeTransform {

  transform(value, args?): Array<any> {
    let searchText = new RegExp(args, 'ig');
    if (value) {
      return value.filter(CarVisitorList => {
        if(CarVisitorList.hostname || CarVisitorList.CarCompName || CarVisitorList.cardnumber || CarVisitorList.CarReason ){
          if(CarVisitorList.hostname.search(searchText) !== -1 || CarVisitorList.CarCompName.search(searchText) !== -1||CarVisitorList.cardnumber.search(searchText) !== -1 || CarVisitorList.CarReason.search(searchText) !== -1 ){
            return true;
          }
        }
      });
    }
  }

}
