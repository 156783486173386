<div widget class="card border-0">
    <div class="card-header transparent border-0 text-muted">
        <h5 class="mb-0">To Do List</h5>
        <div class="widget-controls"> 
            <a data-widgster="expand" href="#" class="transition"><i class="fa fa-chevron-down"></i></a>
            <a data-widgster="collapse" href="#" class="transition"><i class="fa fa-chevron-up"></i></a>                         
            <a data-widgster="close" href="#" class="transition"><i class="fa fa-times"></i></a>
        </div>        
    </div>
    <div class="card-body widget-body">  
        <div class="task-todo-container">
        <div class="row header">
            <div class="col-sm-12">
                <div class="input-group">                    
                    <input type="text" value="" class="form-control" placeholder="Task to do..."
                        (keyup)="addToDoItem($event)" [(ngModel)]="newTodoText"/>
                    <div class="input-group-append">
                        <button class="btn btn-primary" type="button" (click)="addToDoItem($event)">
                            <i class="fa fa-plus add-item-icon"></i>
                        </button>
                    </div>
                </div>
            </div>            
        </div>
        <div class="row">
            <div class="col-sm-12">
                <ul class="todo-list" perfectScrollbar>
                    <li *ngFor="let item of getNotDeleted()" [ngClass]="{checked: item.isChecked, active: item.isActive}"
                        (mouseenter)="item.isActive=true" (mouseleave)="item.isActive=false">
                        <input type="checkbox" [(ngModel)]="item.isChecked">
                        <span class="cut-with-dots">{{ item.text }}</span>
                        <i class="fa fa-trash text-danger" (click)="item.deleted = true"></i>
                    </li>
                </ul>
            </div>
        </div>
        </div>
    </div>
</div>