<div class="sidebar-outer">
    <a class="logo d-flex align-items-center" routerLink="/" (click)="closeSubMenus()" *ngIf="settings.theme.sidebarIsFixed">
        <span class="start">LG</span><span class="ng">VMS</span>
        
    </a>
    <!--
    <button  (click)="refreh()" >refreh</button>
   

    <tr *ngFor="let pageData of menuItems">                        
    
                      
        <div class="vertical-menu">
            <a href="{{pageData.routerLink}}" >{{pageData.title}} </a>
          
          </div>

         
    </tr>
 -->
    <app-vertical-menu [menuItems]="menuItems"></app-vertical-menu>    
</div>


 