import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { branch } from 'src/app/models/branch.model';
import { BranchService } from 'src/app/services/branch/branch.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { Page } from 'src/app/models/Page.model';
import * as XLSX from 'xlsx'; 
@Component({
  selector: 'app-listbranch',
  templateUrl: './listbranch.component.html',
  styleUrls: ['./listbranch.component.scss']
})
export class ListbranchComponent implements OnInit {
  public modalRef: NgbModalRef;
  public searchText: string;
  public p: any;
  public type: string = 'list';
  branchs: branch[] = [];
  Pageies: Page[] = [];
  constructor(
    public modalService: NgbModal,
    private router: Router,
    private branchService: BranchService,private companyService: CompanyService,
  ) {
  }
  ngOnInit(): void {
    this.getListOftable();
    this.getPageper();
  }
  public toggle(type) {
    this.type = type;
  }
  exportAsXLSX():void {  
    const ws: XLSX.WorkSheet=XLSX.utils.json_to_sheet(this.branchs);
    delete (ws['C1'])
    delete (ws['D1'])
    delete (ws['E1'])
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Branch');
    XLSX.writeFile(wb,"Branch"+ '_' + new Date().getTime()+".xlsx")
 }
  getListOftable() {
    this.branchService.getBranchesUrl().subscribe((res: any) => {
      this.branchs = res;
    });

  }

  getPageper() {    
    this.companyService.getPageper(Number(localStorage.getItem('UserId').toString()),2).subscribe((res: any) => {
      this.Pageies = res;            
    });
  }
  view(id: number, button: boolean) {
    this.router.navigate(['/addOrEditbranch/' + id + '/' + button])
  }
  addOrEdit(id: number) {
    this.router.navigate(['/addOrEditbranch', id])
  }
  Delete(element: any) {

    this.branchService.deleteBranch(element.Id).subscribe((res: any) => {
      this.getListOftable();
    });
  }
  public openModal(modalContent, branch) {

    this.modalRef = this.modalService.open(modalContent, { container: '.app' });

    this.modalRef.result.then((result) => {
      if (result === 'yes') {
        this.Delete(branch);
      }
    });
  }
  public closeModal() {
    this.modalRef.close();
  }

}
