<div class="container">
      
    <div  style="text-align: center; ">
       
     <h1> </h1>
      
     <h1>Welcome To LG VMS </h1>
      
 </div>

 </div>