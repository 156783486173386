<div widget class="card border-0">
    <div class="card-header transparent border-0 text-muted">
        <h5 class="mb-0">Visitors</h5>
        <div class="widget-controls"> 
            <a data-widgster="expand" href="#" class="transition"><i class="fa fa-chevron-down"></i></a>
            <a data-widgster="collapse" href="#" class="transition"><i class="fa fa-chevron-up"></i></a>                         
            <a data-widgster="close" href="#" class="transition"><i class="fa fa-times"></i></a>
        </div>        
    </div>
    <div class="card-body pt-0 pl-2 pr-2 pb-2 widget-body">
        <div class="text-center mb-2">
            <div class="custom-control custom-checkbox custom-control-inline">
                <input type="checkbox" class="custom-control-input checkbox-dark-gray" id="checkboxGradient" [checked]="gradient" (change)="gradient = !gradient">
                <label class="custom-control-label" for="checkboxGradient">Gradient</label>
            </div>
            <div class="custom-control custom-checkbox custom-control-inline">
                <input type="checkbox" class="custom-control-input checkbox-dark-gray" id="checkboxTooltip" [checked]="tooltipDisabled" (change)="tooltipDisabled = !tooltipDisabled">
                <label class="custom-control-label" for="checkboxTooltip">Tooltip Disabled</label>
            </div>
        </div>    
        <div class="w-100 h-300p visitors">
            <ngx-charts-tree-map
              [scheme]="colorScheme"        
              [gradient]="gradient"
              [tooltipDisabled]="tooltipDisabled"       
              [labelFormatting]="visitorsLabelFormat"
              [results]="countries"
              (select)="onSelect($event)">
            </ngx-charts-tree-map>
        </div>
    </div>
</div>