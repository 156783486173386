<section class="login-form-section">
    <div class="container">
        <h3 class="text-center mt-2" id="head"> Visitor  Data</h3>
        <div  class="d-flex justify-content-center ">
  
        <div class="card mt-5 w-75">
            <div class="card-body">
        <form #form="ngForm"> 
           
  
                  
            <div class="form-row">
                <div class="form-group col-md-12">
                  <label for="inputAddress">Company Name</label>
                 
                  <select [(ngModel)]="this.Pena.CarComp"   class="form-control"    name="CarComp" id="CarComp">
                    <option  *ngFor="let CarCompany of CarCompanyList" [value]="CarCompany.ID">
                        {{CarCompany.name}}</option>
                </select>
             
                </div>        
              </div>
              
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label for="inputAddress">Vehicle Type</label>
                 
                  <select [(ngModel)]="this.Pena.Carkind"   class="form-control"    name="Carkind" id="Carkind">
                    <option  *ngFor="let VehicalType of VehicalTypeList" [value]="VehicalType.ID">
                        {{VehicalType.name}}</option>
                </select>
             
                </div>        
              </div>

              <div class="form-row">
                <div class="form-group col-md-12">
                  <label for="inputAddress">Reason</label>
                 
                  <select [(ngModel)]="this.Pena.CarReason"   class="form-control"    name="CarReason" id="CarReason">
                    <option  *ngFor="let VisitorReason of VisitorReasonList" [value]="VisitorReason.ID">
                        {{VisitorReason.name}}</option>
                </select>
             
                </div>        
              </div>
   

  

   
    
  
  <div class="form-row">
    <div class="form-group col-md-6">
      <label for="inputAddress">Plate NO </label>
      <textarea rows="1" class="form-control" id="inputAddress" [(ngModel)]="Pena.Carno" name="Carno" id="Carno"
      #note="ngModel" required>
      </textarea>
    </div>   
    
    <div class="form-group col-md-6">
        <label for="inputAddress">Plate Letters </label>
        <textarea rows="1" class="form-control" id="inputAddress" [(ngModel)]="Pena.Carno2" name="Carno2" id="Carno2"
        #note="ngModel" required>
        </textarea>
      </div>  
  </div>
  
  <div class="form-row">
    <label>Visit Date</label>
    <input type="date" class="form-control" [(ngModel)]="Pena.Visitdate" name="Visitdate"  id="Visitdate" #Visitdate="ngModel">
  </div>
  

  <div class="form-row">
    <div class="form-group col-md-12">
      <label for="inputAddress">Notes </label>
      <textarea rows="3" class="form-control" id="inputAddress" [(ngModel)]="Pena.note" name="note" id="note"
      #note="ngModel" required>
      </textarea>
    </div>        
  </div>
  
  
    <div class="d-flex justify-content-center ">
      <button type="submit " id="btn" class="btn btn-success mr-2"   (click)="SubmitAdd();"> Save </button>
      <button type="submit " class="btn btn-outline-primary"  (click)="Backtolist();" routerLinkActive="active"> Back </button>
  
  </div>
        </form>
            </div>
        </div>
    </div>
    </div>
  </section>
  