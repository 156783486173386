<section class="login-form-section">
    <div class="container">
        <h3 class="text-center mt-2" id="head"> Visitor  Data</h3>
        <div  class="d-flex justify-content-center ">
  
        <div class="card mt-5 w-75">
            <div class="card-body">
        <form #form="ngForm"> 
           
  
                  
    
    <div class="form-row">
    <div class="form-group col-md-12">
      <label for="inputAddress">Visitor Name</label>
      <textarea rows="1" class="form-control" id="inputAddress" [(ngModel)]="Pena.visitorname" name="visitorname" id="visitorname"
      #note="ngModel" required>
      </textarea>
    </div>        
  </div>
  
  <div class="form-row">
    <div class="form-group col-md-12">
      <label for="inputAddress">From Company </label>
      <textarea rows="1" class="form-control" id="inputAddress" [(ngModel)]="Pena.company" name="company" id="company"
      #note="ngModel" required>
      </textarea>
    </div>        
  </div>

  <div class="form-row">
    <div class="form-group col-md-12">
      <label for="inputAddress">Reason</label>
     
      <select [(ngModel)]="this.Pena.VisitorReason"   class="form-control"    name="VisitorReason" id="VisitorReason">
        <option  *ngFor="let VisitorReason of VisitorReasonList" [value]="VisitorReason.ID">
            {{VisitorReason.name}}</option>
    </select>
 
    </div>        
  </div>

  <div class="form-row">
    <div class="form-group col-md-12">
      <label for="inputAddress">National ID/Passsport Number </label>
      <textarea rows="1" class="form-control" id="inputAddress" [(ngModel)]="Pena.idcard" name="idcard" id="idcard"
      #note="ngModel" required>
      </textarea>
    </div>        
  </div>
    
  
  <div class="form-row">
    <div class="form-group col-md-12">
      <label for="inputAddress">Mobile Number </label>
      <textarea rows="1" class="form-control" id="inputAddress" [(ngModel)]="Pena.phone" name="phone" id="phone"
      #note="ngModel" required>
      </textarea>
    </div>        
  </div>
  
  <div class="form-row">
    <div class="form-group col-md-12">
      <label for="inputAddress">Notes </label>
      <textarea rows="3" class="form-control" id="inputAddress" [(ngModel)]="Pena.note" name="note" id="note"
      #note="ngModel" required>
      </textarea>
    </div>        
  </div>
  
  <div class="form-row">
    <label>Visit Date</label>
    <input type="date" class="form-control" [(ngModel)]="Pena.Visitdate" name="Visitdate"  id="Visitdate" #Visitdate="ngModel">

    

  </div>
  
    <div class="d-flex justify-content-center ">
      <button type="submit " id="btn" class="btn btn-success mr-2"   (click)="SubmitAdd();"> Save </button>
      <button type="submit " class="btn btn-outline-primary"  (click)="Backtolist();" routerLinkActive="active"> Back </button>
  
  </div>
        </form>
            </div>
        </div>
    </div>
    </div>
  </section>
  