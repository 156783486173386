<div *ngIf="this.alert" class="alert alert-success alert-dismissible fade show" role="alert">
    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
    <strong>Success!</strong> You successfully Save changed this important alert message.

</div>
<!-- add employee  -->
<div class="row" *ngIf="this.empid==0">
    <div class="col">
        <div widget class="card border-0 box-shadow">
            <div class="card-body widget-body">
                <div class="p-2 flex-grow-1 bd-highlight">
                    <a class="btn btn-info add-new" (click)="Backtolist()">
                        <i class="fa fa-arrow-left mr-2"></i>
                    </a>
                </div>

                <form [formGroup]="employeeForm">
                    <div class="row">
                        <div class="col-lg-4 mb-4">
                            <div class="form-group">
                                <label for="Name">Employee Name</label>
                                <input formControlName="Name" type="text" class="form-control" id="Name">
                                <div *ngIf="f['Name'].touched && f['Name'].invalid" class="alert alert-danger">
                                    <div *ngIf="f['Name'].errors && f['Name'].errors['required']">
                                        Employee Nameis required.</div>
                                </div>
                            </div>
                        </div>
                       
                      
                        <div class="col-lg-4 mb-4">
                            <div class="form-group">
                                <label for="jobTitle">Job Title</label>
                                <input formControlName="jobTitle" type="text" class="form-control" id="jobTitle">                                 
                            </div>
                        </div>

                        <div class="col-lg-4 mb-4">
                            <div class="form-group">
                                <label for="jobTitle">Email</label>
                                <input formControlName="Email" type="text" class="form-control" id="Email">                                 
                            </div>
                        </div>
                       

                    </div>
                    <div class="row">
                         
                       
                        <div class="col-lg-4 mb-4">
                            <div class="form-group">
                                <label for="cost">Company</label>
                                <select class="form-control" formControlName="CompID" id="CompID">
                                    <option *ngFor="let cost of Branchs" [value]="cost.ID">{{cost.name}}</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-lg-4 mb-4">
                            <div class="form-group">
                                <label for="Job">Department</label>
                                <select class="form-control" formControlName="DepID" id="DepID">
                                    <option *ngFor="let job of jobs" [value]="job.ID"> {{job.name}}</option>
                                </select>
                            </div>
                        </div>                                           
                       
                        <div class="col-lg-4 mb-4">
                            <div class="form-group">
                                <label for="Dep">user level</label>
                                <select class="form-control" formControlName="userlevel" id="userlevel">
                                    <option *ngFor="let department of departments" [value]="department.ID">
                                        {{department.name}}</option>
                                </select>
                            </div>
                        </div>

                         
                    </div>
                    <div class="row">

                        <div class="col-lg-4 mb-4">
                            <div class="form-group">
                                <label for="jobTitle">Phone</label>
                                <input formControlName="Phone" type="text" class="form-control" id="Phone">                                 
                            </div>
                        </div>

                        <div class="col-lg-4 mb-4">
                            <div class="form-group">
                                <label for="jobTitle">username</label>
                                <input formControlName="username" type="text" class="form-control" id="username">                                 
                            </div>
                        </div>

                        <div class="col-lg-4 mb-4">
                            <div class="form-group">
                                <label for="jobTitle">Password</label>
                                <input formControlName="Password" type="text" class="form-control" id="Password">                                 
                            </div>
                        </div>

                    </div>

                     
                      
                        <div  class="row">
                        <div class="col">
                            <div class="form-groupmt-4">
                                <div class="custom-control custom-checkbox">
                                    <input  type="checkbox" class="custom-control-input checkbox-dark-gray"
                                        formControlName="IsActive" id="IsActive">
                                    <label class="custom-control-label" for="IsActive" >Is Active</label>
                                </div>
                            </div>                            
                        </div>
                        </div>
                        <div  class="row">
                        <div class="col">
                            <div class="form-groupmt-4">
                                <div class="custom-control custom-checkbox">
                                    <input  type="checkbox" class="custom-control-input checkbox-dark-gray"
                                        formControlName="IsManager" id="IsManager">
                                    <label class="custom-control-label" for="IsManager" >Is Manager</label>
                                </div>
                            </div>                            
                        </div>
                    </div>

                    <div  class="row">
                        <div class="col">
                            <div class="form-groupmt-4">
                                <div class="custom-control custom-checkbox">
                                    <input  type="checkbox" class="custom-control-input checkbox-dark-gray"
                                        formControlName="Person" id="Person">
                                    <label class="custom-control-label" for="Person" >Visitor Registration</label>
                                </div>
                            </div>                            
                        </div>
                    </div>

                    <div  class="row">
                        <div class="col">
                            <div class="form-groupmt-4">
                                <div class="custom-control custom-checkbox">
                                    <input  type="checkbox" class="custom-control-input checkbox-dark-gray"
                                        formControlName="Car" id="Car">
                                    <label class="custom-control-label" for="Car" >Vehicle  Registration</label>
                                </div>
                            </div>                            
                        </div>
                    </div>
                 
                        
                      <!--
                    [disabled]="!employeeForm.valid"
                    -->

                    <div class="row justify-content-center mt-5">
                        <button type="submit" class="btn btn-success mr-3" 
                            (click)="submitAdd()">Save</button>
                        <button class="btn btn-danger" (click)="clear()">Clear</button>
                    </div>
                </form>
            </div>
        </div>

    </div>
</div>
<!-- Edit employee  -->


<div class="row" *ngIf="this.empid>0">
    <div class="col">
        <div widget class="card border-0 box-shadow">
            <div class="card-body widget-body">
                <div class="p-2 flex-grow-1 bd-highlight">
                    <a class="btn btn-info add-new" (click)="Backtolist()">
                        <i class="fa fa-arrow-left mr-2"></i>
                    </a>
                </div>
              
                <form [formGroup]="employeeForm">
              
                       
                         
                      

                            <div class="row">
                    
                    <div class="col-lg-1 mb-4">  </div>
                        <div class="col-lg-3 mb-4">
                            <div class="form-group">
                                <label for="Name">Employee Name</label>
                                <input [(ngModel)]="this.empArr.Name" [readonly]="this.button" formControlName="Name" type="text" class="form-control" id="Name">
                                <div *ngIf="f['Name'].touched && f['Name'].invalid" class="alert alert-danger">
                                    <div *ngIf="f['Name'].errors && f['Name'].errors['required']">
                                        Employee Nameis required.</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 mb-4">
                             
                                <div class="form-group">
                                    <label for="InsuranceTitle">Job Title</label>
                                    <input type="text" class="form-control" [(ngModel)]="this.empArr.jobTitle" [readonly]="this.button" class="form-control" formControlName="jobTitle" placeholder="Leave job Title ...">
                                </div>
                           
                        </div>
                        
                        <div class="col-lg-4 mb-4">
                            <div class="form-group">
                                <label for="Phone">Email</label>
                                <input type="text" class="form-control" [(ngModel)]="this.empArr.Email" [readonly]="this.button" class="form-control" formControlName="Email" placeholder="Leave a Email ...">
                            </div>
                        </div>

                       
                       
                    </div>
                   
                    <div class="row">
                        <div class="col-lg-1 mb-4">  </div> 
                        <div class="col-lg-3 mb-4">
                            <div class="form-group">
                                <label for="Cost">Company</label>
                                <select [(ngModel)]="this.empArr.CompID" [disabled]="!this.button" class="form-control" formControlName="CompID" id="CompID">
                                    <option  *ngFor="let cost of Branchs" [value]="cost.ID">{{cost.name}}</option>
                                </select>
                            </div>
                        </div>

                                
                        <div class="col-lg-3 mb-4">
                            <div class="form-group">
                                <label for="Job">Department </label>
                                <select [(ngModel)]="this.empArr.DepID"  class="form-control" formControlName="DepID" id="DepID">
                                    <option  *ngFor="let job of jobs" [value]="job.ID"> {{job.name}}</option>
                                </select>
                            </div>
                        </div>
                   
                        
                    
                        <div class="col-lg-4 mb-4">
                            <div class="form-group">
                                <label for="Dep">user level </label>
                                <select [(ngModel)]="this.empArr.UserLevel"   class="form-control" formControlName="UserLevel" id="UserLevel">
                                    <option  *ngFor="let department of departments" [value]="department.ID">
                                        {{department.name}}</option>
                                </select>
                            </div>
                        </div>

                       
                    </div>
                    
                    <div class="row">
                        <div class="col-lg-1 mb-4">  </div>
                      

                        <div class="col-lg-3 mb-4">
                            <div class="form-group">
                                <label for="Phone">Phone</label>
                                <input type="text" class="form-control" [(ngModel)]="this.empArr.Phone" [readonly]="this.button" class="form-control" formControlName="Phone" placeholder="Leave a Phone ...">
                            </div>
                        </div>

                        <div class="col-lg-3 mb-4">
                            <div class="form-group">
                                <label for="IDcard">username</label>
                                <input type="text" class="form-control" [(ngModel)]="this.empArr.username" [readonly]="this.button" class="form-control" formControlName="username" placeholder="Leave a username...">
                            </div>
                        </div>

                        <div class="col-lg-4 mb-4">
                            <div class="form-group">
                                <label for="Add">Password</label>
                                <input type="text" class="form-control" [(ngModel)]="this.empArr.Password" [readonly]="this.button" class="form-control" formControlName="Password" placeholder="Leave a Password...">
                            </div>
                        </div>

                    </div>

                  
 
                    <div class="col">
                        <div class="form-groupmt-4">
                            <div class="custom-control custom-checkbox">
                                <input [(ngModel)]="this.empArr.IsActive" [readonly]="this.button" [(ngModel)]="this.empArr.IsActive" type="checkbox" class="custom-control-input checkbox-dark-gray"
                                    formControlName="IsActive" id="IsActive">
                                <label class="custom-control-label" for="IsActive" >  Is Active  </label>
                            </div>
                        </div>

                        
                        <div class="form-groupmt-4">
                            <div class="custom-control custom-checkbox">
                                <input [(ngModel)]="this.empArr.IsManager" [readonly]="this.button" [(ngModel)]="this.empArr.IsManager" type="checkbox" class="custom-control-input checkbox-dark-gray"
                                    formControlName="IsManager" id="IsManager">
                                <label class="custom-control-label" for="IsManager" >   Is Manager  </label>
                            </div>
                        </div>

                        <div class="form-groupmt-4">
                            <div class="custom-control custom-checkbox">
                                <input [(ngModel)]="this.empArr.Person" [readonly]="this.button" [(ngModel)]="this.empArr.Person" type="checkbox" class="custom-control-input checkbox-dark-gray"
                                    formControlName="Person" id="Person">
                                <label class="custom-control-label" for="Person" >   Visitor Registration	 </label>
                            </div>
                        </div>

                        <div class="form-groupmt-4">
                            <div class="custom-control custom-checkbox">
                                <input [(ngModel)]="this.empArr.Car" [readonly]="this.button" [(ngModel)]="this.empArr.Car" type="checkbox" class="custom-control-input checkbox-dark-gray"
                                    formControlName="Car" id="Car">
                                <label class="custom-control-label" for="Car" >   Vehicle  Registration	 </label>
                            </div>
                        </div>

                         
  
 
       

                        
                        
                    </div>

                    <div class="row justify-content-center mt-5">
                        <button type="submit" class="btn btn-success mr-3" [disabled]="!employeeForm.valid"
                            (click)="submitEdit()">Update</button>
                        <button class="btn btn-danger" (click)="clear()">Clear</button>
                    </div>

                  
                  
  
 

  
 


                  


                    

                </form>
            </div>
        </div>

    </div>
</div>