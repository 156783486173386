import { Component, OnInit } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { job } from 'src/app/models/job.model';
import { department ,VisitModel} from 'src/app/models/department.model';
import {  DepartmentService} from 'src/app/services/department/department.service';
import { JobService } from 'src/app/services/job/job.service';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-add-ordepartment',
  templateUrl: './add-ordepartment.component.html',
  styleUrls: ['./add-ordepartment.component.scss']
})
export class AddOrdepartmentComponent implements OnInit {
  current:any=localStorage.getItem('UserId');
  VisitorReasonList:job[]=[];
  Pena = new VisitModel;
  alert:boolean=false;
  departments!: department;
  departmentForm:FormGroup;
  departmentId:any;
  button:any;
  constructor(private location: Location,
    private datePipe: DatePipe,
    public departmentservices:DepartmentService,
    
    private jobService:JobService,
    private router: Router,
    private activateRout:ActivatedRoute,) { }
    public modelCustom: NgbDateStruct;
  ngOnInit(): void {
    this.getVisitorReason_List();
    this.departmentId=this.activateRout.snapshot.paramMap.get('id');
    this.button=this.activateRout.snapshot.paramMap.get('button');
    console.log(this.button);
    if(this.departmentId>0)
    {
      this.departmentservices.GetvisitorById(this.departmentId).subscribe((res:any)=>{
        this.Pena = res;
  
        this.Pena.Visitdate  =this.datePipe.transform(this.Pena.Visitdate, 'yyyy-MM-dd', 'en-US');
       
      })

    }
    this.departmentForm = new FormGroup({
      Arname: new FormControl(''),
      Enname: new FormControl('', Validators.required),
    });
  }
  get f(){
    return this.departmentForm.controls;
  }

  
  getVisitorReason_List() {
    this.jobService.getVisitorReason().subscribe((res: any) => {

   this.VisitorReasonList = res;
    });
  }

  SubmitAdd(){
 
    this.Pena.Userlevel=10;
    this.Pena.UserAdded=this.current;
    
    if(this.departmentId>0)
    this.SubmitEdit();
     else
    this.departmentservices.postNewvisitor(this.Pena).subscribe((res:any) => {
         console.log('company Added successfully!');
         this.alert=true;
    })

    this.Backtolist();
  }
  SubmitEdit(){
    console.log(this.departmentForm.value);
    this.departmentservices.Updatevisitor( this.departmentId,this.Pena).subscribe((res:any) => {
         console.log('company Updated successfully!');
         this.location.back();
    })
  }
  Backtolist()
  {
    this.location.back();
  }
  clear()
  {
    this.departmentForm.reset();
    this.alert=false;

  }

}
