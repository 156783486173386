import { Component, OnInit } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { jobLevel,CarVisitorList} from 'src/app/models/jobLevel.model';
import { JobLevelService } from 'src/app/services/jobLevel/job-level.service';
import { job } from 'src/app/models/job.model';

@Component({
  selector: 'app-add-or-editjoblevel',
  templateUrl: './add-or-editjoblevel.component.html',
  styleUrls: ['./add-or-editjoblevel.component.scss']
})
export class AddOrEditjoblevelComponent implements OnInit {
  alert:boolean=false;
  joblevels!: CarVisitorList;
  joblevelForm:FormGroup;
  joblevelId:any;

  CarReasonV:job[]=[];
  CarkindV:job[]=[];
  CarCompV:job[]=[];

  button:any;
  constructor(private location: Location,
    public joblevelservices:JobLevelService,
    private router: Router,
    private activateRout:ActivatedRoute,) { }
    public modelCustom: NgbDateStruct;
  ngOnInit(): void {
    this.joblevelId=this.activateRout.snapshot.paramMap.get('id');
    this.button=this.activateRout.snapshot.paramMap.get('button');
    this.GetCarkind();
    this.GetCarComp();
    this.GetCarReason();
    if(this.joblevelId>0)
    {
      

      this.joblevelservices.getVisitor(this.joblevelId).subscribe((res:CarVisitorList)=>{
        this.joblevels =res
        console.log(res)
      })

    }
    this.joblevelForm = new FormGroup({
      Carno: new FormControl('', [Validators.required]),
      Carno2: new FormControl('', Validators.required),
      hostname:new FormControl,
      Carkind:new FormControl,
      CarComp:new FormControl,
      CarReason:new FormControl,
      DriverName:new FormControl,
      DriverID:new FormControl,
      DriverAdd:new FormControl,
    });
  }
  get f(){
    return this.joblevelForm.controls;
  }

  GetCarReason() {
    this.joblevelservices.GetCarReason().subscribe((res: any) => {

   this.CarReasonV = res;
 
    });
  }

  GetCarComp() {
    this.joblevelservices.getCarComp().subscribe((res: any) => {

   this.CarCompV = res;
 
    });
  }

  GetCarkind() {
    this.joblevelservices.getCarKind().subscribe((res: any) => {

   this.CarkindV = res;
 
    });
  }

  SubmitAdd(){
    console.log(this.joblevelForm.value);
    this.joblevelservices.addJobLevel( this.joblevelForm.value).subscribe((res:any) => {
         console.log('company Added successfully!');
         this.alert=true;
        //  this.location.back();
    })
  }
  SubmitEdit(){
    console.log(this.joblevelForm.value);
    this.joblevelservices.editJobLevel( this.joblevelId,this.joblevelForm.value).subscribe((res:any) => {
         console.log('company Updated successfully!');
         this.location.back();
    })
  }
  Backtolist()
  {
    this.location.back();
  }
  clear()
  {
    this.joblevelForm.reset();
    this.alert=false;

  }

}
