import { HttpClient ,HttpHeaders} from '@angular/common/http';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { empModel } from 'src/app/models/employee.model';


import {  
  Observable  
} from 'rxjs'; 

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  constructor(private http: HttpClient ) { }

  GetCount() {
    //return this.http.get(`${environment.Api}/counting/count?num=11`);
    return this.http.get(`${environment.apiUrl}AttComp/Count`);
      }

  getSalaryband()
  {
    return this.http.get(
      `${environment.apiUrl}SalaryBand/GetSalaryBand`
    );
  }

  getEmpSalaryBand(Id:number)
  {
    return this.http.get(
      `${environment.apiUrl}SalaryBand/EmpSalaryBand/${Id}`
    );
  }

 

  

  addEmpPaper(formData:any)
  {
    return this.http.post(`${environment.apiUrl}EmpDatas/UploadFile`, formData);    
  }

  addEmpSalary(data:any)
  {
    return this.http.post(
      `${environment.apiUrl}SalaryBand/AddEmpSalaryBand`,data
    );
  }

  

  AddEmpPenality(data:any)
  {
    return this.http.post(
      `${environment.apiUrl}SalaryBand/AddEmpPenality`,data
    );
  }
  addEmpExpenses(data:any)
  {
    return this.http.post(
      `${environment.apiUrl}SalaryBand/AddEmpExpenses`,data
    );
  }

  getEmpPaper(Id:number)
  {
    return this.http.get(
      `${environment.apiUrl}EmpDatas/GetAllFiles/${Id}`
    );
  }

  downloadFile(id: number): Observable<Blob> {  
    return this.http.get(`${environment.apiUrl}EmpDatas/DwonloadFile/${id}`, {responseType: 'blob'})        
}

  getEmpExpenses(Id:number)
  {
    return this.http.get(
      `${environment.apiUrl}SalaryBand/EmpExpenses/${Id}`
    );
  }

  getEmpPenality(Id:number)
  {
    return this.http.get(
      `${environment.apiUrl}SalaryBand/EmpPenality/${Id}`
    );
  }


  getEmployeeUrl(id: number)
  {
    return this.http.get(`${environment.apiUrl}employee/List/${id}`);
  }
  getEmployeeIdUrl(Id:number)
  {
    return this.http.get(`${environment.apiUrl}employee/GetEmployee/${Id}`)
  }

  UploadFile (cont : File)
  {
    return this.http.post(
      `${environment.apiUrl}EmpDatas/UploadFile`,cont)
  }

  addemployee(data:empModel)
  {
    return this.http.post(`${environment.apiUrl}employee`, data)
  }
  editDepartment(Id:number,data:empModel)
  {
    return this.http.put(`${environment.apiUrl}employee/PutEmployee/${Id}`, data)
  }

  deleteEmployee(Id:Number)
  {
    return this.http.delete(
      `${environment.apiUrl}EmpDatas/${Id}`
    );
  }

  PostExcelEmp(emp: any[]){
   
    return this.http.post(`${environment.apiUrl}EmpDatas/Excel`, emp)
    
  
  }

  PostExcelEmpSal(emp: any[]){
  
    //let headers = new HttpHeaders();
    //headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    const headers = new HttpHeaders();
    headers.append('Content-Type' , 'application/json; charset=utf-8');
    headers.append('accept' , '*/*');
    headers.append('Access-Control-Allow-Origin', '*');
  

   // let options = new RequestOptions({ headers: headers });

   const httpOptionsPlain = {
    headers: new HttpHeaders({
      'Accept': 'text/plain',
      'Content-Type': 'text/plain'
    }),
    'responseType': 'text'
  };
  
    return this.http.post(`${environment.apiUrl}EmpDatas/ExcelSal`, emp,{headers , responseType: 'text'})
    
  
  }

  PostExcelEmpSalCut(emp: any[]){
 

    const headers = new HttpHeaders();
    headers.append('Content-Type' , 'application/json; charset=utf-8');
    headers.append('accept' , '*/*');
    headers.append('Access-Control-Allow-Origin', '*');
  

   // let options = new RequestOptions({ headers: headers });

   const httpOptionsPlain = {
    headers: new HttpHeaders({
      'Accept': 'text/plain',
      'Content-Type': 'text/plain'
    }),
    'responseType': 'text'
  };
  
    return this.http.post(`${environment.apiUrl}EmpDatas/ExcelSalCut`, emp,{headers , responseType: 'text'})
  }


  CalculateAttendance(){
 

    const headers = new HttpHeaders();
    headers.append('Content-Type' , 'application/json; charset=utf-8');
    headers.append('accept' , '*/*');
    headers.append('Access-Control-Allow-Origin', '*');
  

   // let options = new RequestOptions({ headers: headers });

  
  
    return this.http.get(`${environment.apiUrl}AttRepot/CalculateAttendance`,{headers , responseType: 'json'})
  }

}
