export interface department{
    Id:number;
    Enname:string;
    Arname:string;
    Fdate:string;
    Tdate:string;
}

export class VisitorList{
    id:number;
    visitorname : string;
    company : string;
    idcard:string;

    hostname:string;
    reason:string;
    cardnumber:number;
    phone:string;

    note:string;
    signin:string;
    signout:string;

    Visitdate ?: string;
     
    Compname: string;
    DEPname: string;
    status:number;
    UserAdded:number;
} 

export class VisitModel{
    id:number;
    visitorname : string;
    company : string;
    idcard:string;

    hostname:string;
    reason:string;
    cardnumber:number;
    phone:string;

    note:string;
    signin:string;
    signout:string;

    Visitdate ?: string;
     
    status:number;
    UserAdded:number;
    Userlevel:number;
    VisitorReason:number;
} 

export class CarVisitModel{
    id:number;
    

    hostname:string;
    reason:string;
    cardnumber:number;
       
    signin:string;
    signout:string;
    status:number;
    UserAdded:number;
    Userlevel:number;



    CarReason:number;
    CarComp:number;
    Carkind:number;
    Carno:string;
    Carno2:string;
    Visitdate ?: string;
    VisitdateTo?: string;
    note:string;
} 