<div widget class="card border-0">
    <div class="card-header transparent border-0 text-muted">
        <h5 class="mb-0">Real time cost</h5>
        <div class="widget-controls"> 
            <a data-widgster="expand" href="#" class="transition"><i class="fa fa-chevron-down"></i></a>
            <a data-widgster="collapse" href="#" class="transition"><i class="fa fa-chevron-up"></i></a>                         
            <a data-widgster="close" href="#" class="transition"><i class="fa fa-times"></i></a>
        </div>        
    </div>
    <div class="card-body pt-0 pl-2 pr-2 pb-2 widget-body">
        <div class="text-center mb-2">
            <div class="custom-control custom-checkbox custom-control-inline">
                <input type="checkbox" class="custom-control-input checkbox-dark-gray" id="checkboxCostGradient" [checked]="gradient" (change)="gradient = !gradient">
                <label class="custom-control-label" for="checkboxCostGradient">Gradient</label>
            </div>
            <div class="custom-control custom-checkbox custom-control-inline">
                <input type="checkbox" class="custom-control-input checkbox-dark-gray" id="checkboxCostTooltip" [checked]="tooltipDisabled" (change)="tooltipDisabled = !tooltipDisabled">
                <label class="custom-control-label" for="checkboxCostTooltip">Tooltip Disabled</label>
            </div>
        </div>
        <div class="w-100 h-300p">
            <ngx-charts-area-chart
                [scheme]="colorScheme"
                [results]="cost"
                [gradient]="gradient"
                [tooltipDisabled]="tooltipDisabled"
                [xAxis]="showXAxis"
                [yAxis]="showYAxis"
                [legend]="showLegend"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [xAxisLabel]="xAxisLabel"
                [yAxisLabel]="yAxisLabel"
                [autoScale]="autoScale"
                (select)="onSelect($event)">
            </ngx-charts-area-chart>
        </div>
    </div>
</div>